import { useState, useEffect } from 'react';
import { Select, Space } from 'antd';

const { Option } = Select;

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const DateSelector = ({ handleSelect }) => {
  const DEFAULT_MONTH = months[0];

  const [yearOptions, setYearOptions] = useState<Array<number>>([]);
  const [yearSelection, setYearSelection] = useState<number | null>(2023);

  const [rangeSelected, setRangeSelected] =
    useState<{
      start: string;
      end: string;
    } | null>(null);

  const formatDate = (d: Date) => {
    return d.toLocaleDateString('en-GB').split('/').reverse().join('-');
  };

  const handleChange = (month: string) => {
    if (!yearSelection) return null;

    const monthIdx = months.indexOf(month);
    const nextMonth = monthIdx + 1;

    const start = new Date(yearSelection, monthIdx, 1);
    const end = new Date(yearSelection, nextMonth, 1);

    const _start = formatDate(start);
    const _end = formatDate(end);

    setRangeSelected({
      start: start.toLocaleDateString('en-GB', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }),
      end: end.toLocaleDateString('en-GB', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }),
    });

    handleSelect({ start: _start, end: _end });
  };

  useEffect(() => {
    const now = new Date();
    const thisYear = now.getFullYear();
    setYearOptions([thisYear, thisYear - 1]);
    setYearSelection(thisYear);

    handleChange(DEFAULT_MONTH);
  }, []);

  return (
    <Space
      style={{
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto 3rem',
      }}
      size="large"
    >
      {yearOptions.length > 0 && (
        <Select
          style={{ width: 200 }}
          defaultValue={yearOptions[0]}
          onChange={(year) => setYearSelection(year)}
        >
          {yearOptions.map((year) => (
            <Option key={year} value={year}>
              {year}
            </Option>
          ))}
        </Select>
      )}
      <Select
        style={{ width: 200 }}
        defaultValue={DEFAULT_MONTH}
        onChange={(month) => handleChange(month)}
      >
        {months.map((month) => (
          <Option key={month} value={month}>
            {month}
          </Option>
        ))}
      </Select>
      {rangeSelected && (
        <p>{`Getting costs for date range ${rangeSelected.start} to ${rangeSelected.end}`}</p>
      )}
    </Space>
  );
};

export default DateSelector;
