import { Layout } from 'antd';
import { Switch, Route } from 'react-router-dom';
import Member from 'Components/Member';
import Product from 'Components/Product';
import ProductItem from 'Components/ProductItem';
import Vendor from 'Components/Vendor';
import Service from 'Components/Service';
import HomePage from 'Components/Home';
import Billing from 'Components/Billing';
import { TenantCreateNew, TenantDeployStacks } from 'Components/Tenant';
import { OnboardingInvitations } from 'Components/Onboarding';
import StandardProjectPage from './StandardProject';
import ProductPricingUpload from './ProductPricingUpload';

const { Content } = Layout;

const router = () => {
  return (
    <Content style={{ margin: '0 auto', background: 'transparent' }}>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <Switch>
          <Route path="/member/list" component={Member} />
          <Route path="/catalog/product" component={Product} />
          <Route path="/catalog/component" component={ProductItem} />
          <Route path="/catalog/vendor" component={Vendor} />
          <Route path="/catalog/service" component={Service} />
          <Route
            path="/catalog/productpricing"
            component={ProductPricingUpload}
          />
          <Route path="/tenant/billing" component={Billing} />
          <Route path="/tenant/create" component={TenantCreateNew} />
          <Route path="/tenant/deploy" component={TenantDeployStacks} />
          <Route
            path="/onboarding/invitations"
            component={OnboardingInvitations}
          />
          <Route path="/standardproject" component={StandardProjectPage} />
          <Route exact path="/" component={HomePage} />
        </Switch>
      </div>
    </Content>
  );
};

export default router;
