import React, { Fragment, useEffect, useReducer } from 'react';
import { Form, Input, Button } from 'antd';
import { CollapsableCard } from 'tyrell-common-libs-web';

const { Item } = Form;

export interface Address {
  addressLine1: string;
  addressLine2: string;
  city: string;
  country: string;
  postcode: string;
}

export default ({
  handleUpdate,
  initialAddress,
}: {
  handleUpdate: React.Dispatch<React.SetStateAction<Address>>;
  initialAddress: Address;
}) => {
  const [form] = Form.useForm();

  const initialState: Address = initialAddress;

  const reducer = (state: Address, action) => {
    const { type, payload } = action;
    return { ...state, [type]: payload };
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    handleUpdate(state);
  }, [state]);

  return (
    <CollapsableCard title="Address">
      <Fragment>
        <Form
          form={form}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
        >
          <Item label="Address Line 1" required>
            <Input
              onChange={({ target: { value } }) =>
                dispatch({ type: 'addressLine1', payload: value })
              }
            />
          </Item>
          <Item label="Address Line 2" required>
            <Input
              onChange={({ target: { value } }) =>
                dispatch({ type: 'addressLine2', payload: value })
              }
            />
          </Item>
          <Item label="City" required>
            <Input
              onChange={({ target: { value } }) =>
                dispatch({ type: 'city', payload: value })
              }
            />
          </Item>
          <Item label="Country" required>
            <Input
              onChange={({ target: { value } }) =>
                dispatch({ type: 'country', payload: value })
              }
            />
          </Item>
          <Item label="Postcode" required>
            <Input
              onChange={({ target: { value } }) =>
                dispatch({ type: 'postcode', payload: value })
              }
            />
          </Item>
        </Form>
      </Fragment>
    </CollapsableCard>
  );
};
