import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import List from 'Components/Billing/List';
import AwsCosts from 'Components/Billing/AwsCosts';
import { Fragment, useState } from 'react';
import { useRedux } from 'Hooks';
import { Button, Space } from 'antd';
import { PageContainer, CollapsableCard } from '../common';
import DateSelector from './DateSelector';

const TenantContainer = () => {
  const { dispatch, actions, store } = useRedux();

  const {
    tenantActions: { getAwsCosts },
  } = actions;

  const [selectedDayRange, setSelectedDayRange] = useState<{
    start: string;
    end: string;
  }>({
    start: '',
    end: '',
  });

  const {
    tenant: { all, selected, awsCosts },
  } = store;

  const {
    tenantActions: { select },
  } = actions;

  const handleTableSelection = (selectedRowKeys: React.Key[]) => {
    const _selection = all.find((p) => p.id === String(selectedRowKeys));
    _selection && dispatch(select({ id: _selection.id }));
  };

  return (
    <PageContainer title="Billing">
      <Fragment>
        <CollapsableCard title="Select tenant">
          <List handleSelection={handleTableSelection} />
        </CollapsableCard>
        {selected && (
          <CollapsableCard title="Select date range">
            <Space
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
              }}
              size="small"
            >
              <DateSelector handleSelect={setSelectedDayRange} />
              <Button
                type="primary"
                disabled={
                  selectedDayRange.start.length === 0 &&
                  selectedDayRange.end.length === 0 &&
                  !selected
                }
                onClick={() =>
                  selected &&
                  dispatch(
                    getAwsCosts({
                      tenantId: parseInt(selected),
                      start: selectedDayRange.start,
                      end: selectedDayRange.end,
                    })
                  )
                }
              >
                Get AWS Costs
              </Button>
            </Space>
          </CollapsableCard>
        )}
        {!!awsCosts && <AwsCosts awsCosts={awsCosts} />}
      </Fragment>
    </PageContainer>
  );
};

export default TenantContainer;
