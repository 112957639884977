import { ProductCategory } from 'Api/types.generated';
import Type from 'Controller/Product/Product.type';
import { ReduxMiddlewareFunction } from 'Controller/middleware';
import { productActions } from 'Controller/actions';
import {
  listProducts,
  createProduct,
  upsertProduct,
  deleteProduct,
  removeItemInProduct,
  upsertItemInProduct,
} from 'Api';
import apiCallWrapper from 'Api/apiCallWrapper';

const { listAll, setAll, select } = productActions;

type Middleware = Record<Type, ReduxMiddlewareFunction>;

const middleware: Middleware = {
  [Type.LIST_ALL]: async (store, next, action, apiClient) => {
    const { dispatch } = store;

    await apiCallWrapper({
      dispatch,
      uiMessage: 'Products ...',
      callback: async () => {
        const response = await apiClient.query({ query: listProducts });
        dispatch(setAll({ products: response.data.listProducts }));
      },
    });

    next(action);
  },

  [Type.SET_ALL]: (store, next, action, apiClient) => {
    const { products } = action.payload;

    const byId = (p) => p.id;

    const byCategory = (category) => (product) => product.category === category;

    const teradici = products
      .filter(byCategory(ProductCategory.Teradici))
      .map(byId);

    const editShare = products
      .filter(byCategory(ProductCategory.Editshare))
      .map(byId);

    const workstation = products
      .filter(byCategory(ProductCategory.Workstation))
      .map(byId);

    const transfer = products
      .filter(byCategory(ProductCategory.Transfer))
      .map(byId);

    const activeDirectory = products
      .filter(byCategory(ProductCategory.ActiveDirectory))
      .map(byId);

    action.payload = {
      products,
      categorizeById: {
        teradici,
        editShare,
        workstation,
        transfer,
        activeDirectory,
      },
    };

    next(action);
  },

  [Type.UPDATE]: async (store, next, action, apiClient) => {
    const { dispatch } = store;

    const {
      product: {
        id,
        description,
        category,
        name,
        serviceId,
        meta,
        showPrice,
        vendorId,
      },
    } = action.payload;

    await apiCallWrapper({
      dispatch,
      uiMessage: `Updating ${name} ...`,
      callback: async () => {
        const response = await apiClient.mutate({
          mutation: upsertProduct,
          variables: {
            id,
            description,
            category,
            name,
            serviceId,
            meta,
            showPrice,
            vendorId,
          },
        });
        dispatch(listAll());
        dispatch(select({ id: response.data.upsertProduct.id }));
      },
    });

    next(action);
  },

  [Type.CREATE]: async (store, next, action, apiClient) => {
    const { dispatch } = store;

    const {
      product: {
        description,
        category,
        name,
        serviceId,
        meta,
        showPrice,
        vendorId,
      },
    } = action.payload;

    await apiCallWrapper({
      dispatch,
      uiMessage: `Creating ${name} ...`,
      callback: async () => {
        const response = await apiClient.mutate({
          mutation: createProduct,
          variables: {
            description,
            category,
            name,
            serviceId,
            meta,
            showPrice,
            vendorId,
          },
        });
        dispatch(listAll());
        dispatch(select({ id: response.data.createProduct.id }));
      },
    });

    next(action);
  },

  [Type.REMOVE]: async (store, next, action, apiClient) => {
    const { dispatch } = store;

    const { id } = action.payload;

    await apiCallWrapper({
      dispatch,
      uiMessage: 'Removing item from product...',
      callback: async () => {
        await apiClient.mutate({
          mutation: deleteProduct,
          variables: { productId: id },
        });
        dispatch(listAll());
        dispatch(select({ id: null }));
      },
    });

    next(action);
  },

  [Type.REMOVE_ITEM]: async (store, next, action, apiClient) => {
    const { dispatch } = store;

    const { productId, productItemId } = action.payload;

    await apiCallWrapper({
      dispatch,
      uiMessage: 'Removing item from product...',
      callback: async () => {
        await apiClient.mutate({
          mutation: removeItemInProduct,
          variables: { productId, productItemId },
        });
        dispatch(listAll());
        dispatch(select({ id: productId }));
      },
    });

    next(action);
  },

  [Type.UPSERT_ITEM]: async (store, next, action, apiClient) => {
    const { dispatch } = store;

    const { productId, productItemId, quantity } = action.payload;

    await apiCallWrapper({
      dispatch,
      uiMessage: 'Adding item to product...',
      callback: async () => {
        await apiClient.mutate({
          mutation: upsertItemInProduct,
          variables: { productId, productItemId, quantity },
        });
        dispatch(listAll());
        dispatch(select({ id: productId }));
      },
    });

    next(action);
  },

  [Type.SELECT]: async (store, next, action, apiClient) => {
    next(action);
  },
};

export default middleware;
