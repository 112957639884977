import List from 'Components/ProductItem/List';
import Detail from 'Components/ProductItem/Detail';
import New from 'Components/ProductItem/New';
import Configuration from 'Components/ProductItem/Configuration';
import { useRedux } from 'Hooks';
import { Space, Card, Typography } from 'antd';
import React, { useState } from 'react';
import _get from 'lodash.get';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

const Container = () => {
  const [showSearch, setShowSearch] = useState(true);
  const [showDetail, setShowDetail] = useState(true);
  const [showConfiguration, setShowConfiguration] = useState(true);

  const { dispatch, actions, store } = useRedux();

  const {
    productItemActions: { select },
  } = actions;

  const {
    productItem: { all, selected },
    vendor: { all: allVendors },
  } = store;

  const handleTableSelection = (selectedRowKeys: React.Key[]) => {
    const _selection = all.find((pi) => pi.id === String(selectedRowKeys));
    _selection && dispatch(select({ id: _selection.id }));
    document.getElementById('product-detail-form')?.scrollIntoView();
    setShowDetail(true);
  };

  return (
    <Space
      style={{
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
      }}
      size="large"
    >
      <Typography.Title level={2} style={{ width: 762, textAlign: 'left' }}>
        Components
      </Typography.Title>
      <Card
        title="Search"
        style={{ width: 762 }}
        extra={
          showSearch ? (
            <DownOutlined onClick={() => setShowSearch(false)} />
          ) : (
            <UpOutlined onClick={() => setShowSearch(true)} />
          )
        }
        bodyStyle={{ display: showSearch ? 'block' : 'none' }}
      >
        <List handleSelection={handleTableSelection} />
      </Card>
      <Card
        title="Details"
        style={{ width: 762 }}
        id={'product-detail-form'}
        extra={
          showDetail ? (
            <DownOutlined onClick={() => setShowDetail(false)} />
          ) : (
            <UpOutlined onClick={() => setShowDetail(true)} />
          )
        }
        bodyStyle={{ display: showDetail ? 'block' : 'none' }}
      >
        <Detail />
      </Card>
      <Card
        title="Configuration"
        style={{ width: 762 }}
        extra={
          showConfiguration ? (
            <DownOutlined onClick={() => setShowConfiguration(false)} />
          ) : (
            <UpOutlined onClick={() => setShowConfiguration(true)} />
          )
        }
        bodyStyle={{ display: showConfiguration ? 'block' : 'none' }}
      >
        <Configuration />
      </Card>
    </Space>
    // allVendors.length > 0 && (
    //   <div className="container-fluid">
    //     <div className="row">
    //       <div className="col-6">
    //         <h2>Product items</h2>
    //       </div>
    //       <div className="col-5 ms-auto"></div>
    //     </div>
    //     <div className="row --tri-panel">
    //       <div className="col-4">
    //         <List />
    //         {selected && (
    //           <button
    //             type="button"
    //             className="btn btn-outline-dark"
    //             onClick={() => dispatch(select({ id: null }))}
    //           >
    //             Create new
    //           </button>
    //         )}
    //       </div>
    //       <div className="col-4">{selected ? <Detail /> : <New />}</div>
    //       <div className="col-4">{selected && <Configuration />}</div>
    //     </div>
    //   </div>
    // )
  );
};

export default Container;
