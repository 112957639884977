import Types from './Service.type';

export const listAll = () => {
  return {
    type: Types.LIST_ALL,
    payload: null,
  };
};

export const setAll = ({ services }: { services: Array<any> }) => {
  return {
    type: Types.SET_ALL,
    payload: { services },
  };
};

export const select = ({ id }: { id: string | null }) => {
  return {
    type: Types.SELECT,
    payload: { id },
  };
};

export const update = ({ service }: { service: any }) => {
  return {
    type: Types.UPDATE,
    payload: { service },
  };
};

export const create = ({ service }: { service: any }) => {
  return {
    type: Types.CREATE,
    payload: { service },
  };
};

export const remove = ({ id }: { id: string }) => {
  return {
    type: Types.REMOVE,
    payload: { id },
  };
};
