import { useEffect, useState } from 'react';
import { useRedux } from 'Hooks';
import AppRouter from 'Components/router';
import Network from 'Components/Network';
import { checkCookie } from 'Utils';
import { useHistory } from 'react-router-dom';
import {
  INTERNAL_SUBDOMAIN,
  INavigationConfigInternal,
  UiLayout,
} from 'tyrell-common-libs-web';

const internalNavigationConfig: INavigationConfigInternal = {
  [INTERNAL_SUBDOMAIN.COG]: {
    //@ts-ignore
    __type: 'internal',
    subDomain: 'cog',
    displayName: 'Cost of Goods',
    displayShortName: 'COG',
    routes: [
      { route: '/', displayName: 'Bill of Materials', subRoutes: [] },
      { route: '/forex', displayName: 'Forex', subRoutes: [] },
      {
        route: '/cost',
        displayName: 'Cost models',
        subRoutes: [
          { route: '/costs', displayName: 'List all', subRoutes: [] },
          { route: '/cost', displayName: 'New', subRoutes: [] },
        ],
      },
    ],
  },
  [INTERNAL_SUBDOMAIN.ADMIN]: {
    //@ts-ignore
    __type: 'internal',
    subDomain: 'admin',
    displayName: 'Administration',
    displayShortName: 'Admin',
    routes: [
      { route: '/', displayName: 'Home', subRoutes: [] },
      {
        route: '/member',
        displayName: 'Member',
        subRoutes: [
          {
            route: '/member/list',
            displayName: 'List',
            subRoutes: [],
          },
        ],
      },
      {
        route: '/tenant',
        displayName: 'Tenant',
        subRoutes: [
          {
            route: '/tenant/billing',
            displayName: 'Billing',
            subRoutes: [],
          },
          {
            route: '/tenant/create',
            displayName: 'Create',
            subRoutes: [],
          },
          {
            route: '/tenant/deploy',
            displayName: 'Deploy',
            subRoutes: [],
          },
        ],
      },
      {
        route: '/onboarding',
        displayName: 'Onboarding',
        subRoutes: [
          {
            route: '/onboarding/invitations',
            displayName: 'Invitations',
            subRoutes: [],
          },
        ],
      },
      {
        route: '/catalog',
        displayName: 'Catalog',
        subRoutes: [
          { route: '/catalog/product', displayName: 'Product', subRoutes: [] },
          {
            route: '/catalog/component',
            displayName: 'Component',
            subRoutes: [],
          },
          { route: '/catalog/vendor', displayName: 'Vendor', subRoutes: [] },
          { route: '/catalog/service', displayName: 'Service', subRoutes: [] },
          {
            route: '/catalog/productpricing',
            displayName: 'Product Pricing',
            subRoutes: [],
          },
        ],
      },
      {
        route: '/standardproject',
        displayName: 'Standard Project',
        subRoutes: [],
      },
    ],
  },
};

function App() {
  const [accessAllowed, setAccessAllowed] = useState(false);

  const history = useHistory();

  const handleNavigation = (e) => {
    const route = e.key.split('-').find((s) => s.includes('/'));
    history.push(route);
  };

  const {
    dispatch,
    actions,
    store: {
      member: { thisSessionMember },
    },
  } = useRedux();

  useEffect(() => {
    const cookieToken = checkCookie();
    if (cookieToken) {
      setAccessAllowed(true);
    }
  }, []);

  useEffect(() => {
    if (accessAllowed) {
      dispatch(actions.memberActions.getMe());
      dispatch(actions.vendorActions.listAll());
      dispatch(actions.serviceActions.listAll());
      dispatch(actions.memberActions.listAll());
      dispatch(actions.productActions.listAll());
      dispatch(actions.productItemActions.listAll());
      dispatch(actions.tenantActions.listAll());
      dispatch(actions.standardProjectActions.listAll());
    }
  }, [dispatch, actions, accessAllowed]);

  return accessAllowed ? (
    <div className="App">
      <Network />
      <UiLayout
        appName="Admin"
        navigationConfig={{
          global: {
            navItems: [
              {
                displayName: 'Admin',
                subDomain: 'admin',
              },
              {
                displayName: 'Cost of Goods',
                subDomain: 'cog',
              },
            ],
          },
          local: {
            navItems: [
              { route: '/', displayName: 'Home', subRoutes: [] },
              {
                route: '/member',
                displayName: 'Member',
                subRoutes: [
                  {
                    subDomain: 'admin',
                    route: '/member/list',
                    displayName: 'List',
                    subRoutes: [],
                  },
                ],
              },
              {
                route: '/tenant',
                displayName: 'Tenant',
                subRoutes: [
                  {
                    subDomain: 'admin',
                    route: '/tenant/billing',
                    displayName: 'Billing',
                    subRoutes: [],
                  },
                  {
                    subDomain: 'admin',
                    route: '/tenant/create',
                    displayName: 'Create',
                    subRoutes: [],
                  },
                  {
                    subDomain: 'admin',
                    route: '/tenant/deploy',
                    displayName: 'Deploy',
                    subRoutes: [],
                  },
                ],
              },
              {
                route: '/onboarding',
                displayName: 'Onboarding',
                subRoutes: [
                  {
                    subDomain: 'admin',
                    route: '/onboarding/invitations',
                    displayName: 'Invitations',
                    subRoutes: [],
                  },
                ],
              },
              {
                route: '/catalog',
                displayName: 'Catalog',
                subRoutes: [
                  {
                    subDomain: 'admin',
                    route: '/catalog/product',
                    displayName: 'Product',
                    subRoutes: [],
                  },
                  {
                    subDomain: 'admin',
                    route: '/catalog/component',
                    displayName: 'Component',
                    subRoutes: [],
                  },
                  {
                    subDomain: 'admin',
                    route: '/catalog/vendor',
                    displayName: 'Vendor',
                    subRoutes: [],
                  },
                  {
                    subDomain: 'admin',
                    route: '/catalog/service',
                    displayName: 'Service',
                    subRoutes: [],
                  },
                  {
                    subDomain: 'admin',
                    route: '/catalog/productpricing',
                    displayName: 'Product Pricing',
                    subRoutes: [],
                  },
                ],
              },
              {
                route: '/standardproject',
                displayName: 'Standard Project',
                subRoutes: [],
              },
            ],
          },
        }}
        member={{
          givenName: thisSessionMember?.givenName || '',
          familyName: thisSessionMember?.familyName || '',
          email: thisSessionMember?.email || '',
          sessionExpiry: '',
        }}
      >
        <AppRouter />
      </UiLayout>
    </div>
  ) : (
    <div className="logo-load">
      <img
        height={150}
        src="https://tyrellcct.com/wp-content/uploads/2019/03/tyrelllogosm.png"
      />
      <p>Checking access credentials...</p>
    </div>
  );
}

export default App;
