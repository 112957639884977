import { useRedux } from 'Hooks';
import ForeignRelationshipsFrom from 'Components/Product/Forms/ForeignRelationships';

export type HandleUpsertItemFunction = ({
  productId,
  productItemId,
  quantity,
}: {
  productId: string;
  productItemId: string;
  quantity: number;
}) => void;

export type HandleRemoveItemFunction = ({
  productId,
  productItemId,
}: {
  productId: string;
  productItemId: string;
}) => void;

const Items = () => {
  const { dispatch, actions, store } = useRedux();

  const {
    productActions: { removeItem, upsertItem },
  } = actions;

  const {
    product: { all, selected },
  } = store;

  const product = all.find((p) => p.id === selected);

  const handleUpsertItem: HandleUpsertItemFunction = (formValues) => {
    dispatch(upsertItem({ ...formValues }));
  };

  const handleRemoveItem: HandleRemoveItemFunction = (formValues) => {
    dispatch(removeItem({ ...formValues }));
  };

  return (
    <ForeignRelationshipsFrom
      product={product || null}
      handleUpsertItem={handleUpsertItem}
      handleRemoveItem={handleRemoveItem}
    />
  );
};

export default Items;
