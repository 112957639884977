export enum StackDeployment {
  ACM_CERTIFICATE = 'deployTenantCertificateStack',
  AD_CERTIFICATE_AUTHORITY = 'deployTenantCertificateAuthority',
  AD_FSX = 'deployTenantActiveDirectory',
  DIRECTORY_INFRASTRUCTURE = 'deployDirectoryInfrastructure',
  AD_ADMINS = 'createActiveDirectoryAdmins',
}

enum Types {
  SET_ALL = '[TENANT] SET_ALL',
  LIST_ALL = '[TENANT] LIST_ALL',
  SELECT = '[TENANT] SELECT',
  GET_AWS_COSTS = '[TENANT] GET_AWS_COSTS',
  SET_AWS_COSTS = '[TENANT] SET_AWS_COSTS',
  CREATE_NEW = '[TENANT] CREATE_NEW',
  LIST_STACKS = '[TENANT] LIST_STACKS',
  DEPLOY_STACK = '[TENANT] DEPLOY_STACK',
  PAGE_ROUTE = '[TENANT] PAGE_ROUTE',
}

export default Types;
