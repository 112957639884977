import { Button, Form, Input, Modal, Table, TableColumnsType } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useRedux } from 'Hooks';
import React from 'react';
import { PageContainer } from 'tyrell-common-libs-web';
import { StandardProjectListItem_DTO } from 'Controller/StandardProject/StandardProject.type';
import { calculateUrl, restApi } from 'ApiRest/client';
import {
  addActivity,
  addError,
  removeActivity,
  removeError,
} from 'Controller/Network/Network.action';
import { standardProjectActions } from 'Controller/actions';

const uuid = () => Math.random().toString(36).slice(2);

const StandardProjectPage = () => {
  const { dispatch, actions, store } = useRedux();
  const [isBillingModalVisible, setIsBillingModalVisible] =
    React.useState(false);
  const [billingModalData, setbillingModalData] =
    React.useState<StandardProjectListItem_DTO | null>(null);
  const [isActioModalVisible, setIsActionModalVisible] = React.useState(false);
  const [actionModalType, setActionModalType] =
    React.useState<null | 'approve' | 'reject'>(null);
  const [tyrellCustomerIdInput, setTyrellCustomerIdInput] = React.useState('');

  const showModal = (record) => {
    setbillingModalData(record);
    setIsBillingModalVisible(true);
  };

  const closeBillingModal = () => {
    setIsBillingModalVisible(false);
    setbillingModalData(null);
  };
  const closeActionModal = () => {
    setIsActionModalVisible(false);
    setActionModalType(null);
  };

  const executeAction = () => {
    if (!billingModalData?.id) {
      const activityId = uuid();
      dispatch(
        addError({
          id: activityId,
          message: 'Project failed to select',
        })
      );
      dispatch(removeError({ id: activityId }));

      return;
    }
    if (actionModalType === 'approve') {
      if (!tyrellCustomerIdInput) {
        const activityId = uuid();
        dispatch(
          addError({
            id: activityId,
            message: 'Missing tyrell customer ID',
          })
        );
        dispatch(removeError({ id: activityId }));
        return;
      }
      acceptRequest({
        requestId: billingModalData.id,
        tyrellCustomerId: tyrellCustomerIdInput,
      });
    } else if (actionModalType === 'reject') {
      rejectRequest({ requestId: billingModalData.id });
    }
    dispatch(standardProjectActions.listAll());
    setIsActionModalVisible(false);
    setActionModalType(null);
  };

  const acceptRequest = async ({
    requestId,
    tyrellCustomerId,
  }: {
    requestId: number;
    tyrellCustomerId: string;
  }) => {
    try {
      const approveRejectStandardProjectsUrl = calculateUrl({
        subdomain: 'dashboard',
        path: `api/admin/standard-project/${requestId}`,
      });

      const response = await restApi(approveRejectStandardProjectsUrl, {
        body: JSON.stringify({
          status: 'approved',
          tyrellCustomerId,
        }),
        method: 'POST',
      });
      const activityId = uuid();
      dispatch(
        addActivity({
          id: activityId,
          message: 'Project Approved',
          type: 'success',
        })
      );
      dispatch(removeActivity({ id: activityId }));
    } catch (e) {
      const _e = e as Error;
      const activityId = uuid();
      dispatch(
        addActivity({
          id: activityId,
          message: String(`${_e.name} - ${_e.message}`),
          type: 'error',
        })
      );
      dispatch(removeActivity({ id: activityId }));
    }
  };
  const rejectRequest = async ({ requestId }: { requestId: number }) => {
    try {
      const approveRejectStandardProjectsUrl = calculateUrl({
        subdomain: 'dashboard',
        path: `api/admin/standard-project/${requestId}`,
      });
      const response = await restApi(approveRejectStandardProjectsUrl, {
        body: JSON.stringify({
          status: 'rejected',
        }),
        method: 'POST',
      });
      const activityId = uuid();
      addActivity({
        id: activityId,
        message: 'Project Rejected',
        type: 'success',
      });
      dispatch(removeActivity({ id: activityId }));
    } catch (e) {
      const _e = e as Error;
      const activityId = uuid();
      dispatch(
        addActivity({
          id: activityId,
          message: String(`${_e.name} - ${_e.message}`),
          type: 'error',
        })
      );
      dispatch(removeActivity({ id: activityId }));
    }
  };

  const {
    standardProject: { all },
  } = store;

  const columns: TableColumnsType<StandardProjectListItem_DTO> = [
    {
      title: 'Project Name',
      dataIndex: 'projectName',
      key: 'projectName',
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
    },
    {
      title: 'Billing Approved',
      dataIndex: 'billingDetailsApproved',
      key: 'billingDetailsApproved',
      render: (
        billingDetailsApproved: boolean,
        record: StandardProjectListItem_DTO
      ) => (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span style={{ marginTop: 'auto', marginBottom: 'auto' }}>
            {billingDetailsApproved ? 'Yes' : 'No'}
          </span>
          <Button
            shape="circle"
            icon={<InfoCircleOutlined />}
            onClick={() => {
              showModal(record);
            }}
          ></Button>
        </div>
      ),
      filters: [
        {
          text: 'Approved',
          value: true,
        },
        {
          text: 'Awaiting Approval',
          value: false,
        },
      ],
      onFilter: (value, record: StandardProjectListItem_DTO) =>
        record.billingDetailsApproved === value,
    },
    {
      title: 'Project Status',
      dataIndex: 'projectStatus',
      key: 'projectStatus',
      filters: [
        {
          text: 'Approved',
          value: 'approved',
        },
        {
          text: 'Pending',
          value: 'pending',
        },
        {
          text: 'Rejected',
          value: 'rejected',
        },
      ],
      onFilter: (value, record) => record.projectStatus === value,
      defaultFilteredValue: ['pending'],
    },
    {
      title: 'Tyrell Customer Id',
      dataIndex: 'tyrellCustomerId',
      key: 'tyrellCustomerId',
    },
    {
      title: 'Created',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date: string) => new Date(date).toLocaleDateString(),
      sorter: (a, b) =>
        new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1,
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record: StandardProjectListItem_DTO) => {
        if (record.projectStatus === 'pending') {
          return (
            <>
              <Button
                onClick={() => {
                  setbillingModalData(record);
                  setActionModalType('approve');
                  setIsActionModalVisible(true);
                }}
              >
                Accept
              </Button>
              <Button
                onClick={() => {
                  setbillingModalData(record);
                  setActionModalType('reject');
                  setIsActionModalVisible(true);
                }}
              >
                Reject
              </Button>
            </>
          );
        }
      },
    },
  ];

  return (
    <PageContainer title="Standard Projects">
      <>
        <Table dataSource={all} columns={columns} />
        <Modal
          title="Billing Details"
          open={isBillingModalVisible}
          onOk={closeBillingModal}
          onCancel={closeBillingModal}
        >
          <p>First Name: {billingModalData?.firstName}</p>
          <p>Last Name: {billingModalData?.lastName}</p>
          <p>Email: {billingModalData?.email}</p>
          <p>Address Line 1: {billingModalData?.addressLine1}</p>
          <p>Address Line 2: {billingModalData?.addressLine2}</p>
          <p>Country: {billingModalData?.country}</p>
          <p>City: {billingModalData?.city}</p>
          <p>Post Code: {billingModalData?.postCode}</p>
          <p>
            tyrellCustomerId: {billingModalData?.tyrellCustomerId || 'Not Set'}
          </p>
          <p>
            Billing Details Approve:{' '}
            {billingModalData?.billingDetailsApproved ? 'Yes' : 'No'}
          </p>
        </Modal>
        <Modal
          title={`Project ${
            actionModalType === 'approve' ? 'Approval' : 'Rejection'
          }`}
          open={isActioModalVisible}
          onOk={executeAction}
          onCancel={closeActionModal}
        >
          {actionModalType === 'approve' ? (
            <>
              <p>First Name: {billingModalData?.firstName}</p>
              <p>Last Name: {billingModalData?.lastName}</p>
              <p>Email: {billingModalData?.email}</p>
              <p>Address Line 1: {billingModalData?.addressLine1}</p>
              <p>Address Line 2: {billingModalData?.addressLine2}</p>
              <p>Country: {billingModalData?.country}</p>
              <p>City: {billingModalData?.city}</p>
              <p>Post Code: {billingModalData?.postCode}</p>
              <p>
                tyrellCustomerId:{' '}
                {billingModalData?.tyrellCustomerId || 'Not Set'}
              </p>
              <p>
                Billing Details Approve:{' '}
                {billingModalData?.billingDetailsApproved ? 'Yes' : 'No'}
              </p>

              <Form.Item name={'tyrellCustomerId'} label={'Tyrell CustomerId'}>
                <Input
                  onChange={(e) => setTyrellCustomerIdInput(e.target.value)}
                  value={tyrellCustomerIdInput}
                />
              </Form.Item>
            </>
          ) : (
            <p>
              Please confirm that you want to reject this project application
            </p>
          )}
        </Modal>
      </>
    </PageContainer>
  );
};

export default StandardProjectPage;
