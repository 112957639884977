import Types from 'Controller/Vendor/Vendor.type';

export const listAll = () => {
  return {
    type: Types.LIST_ALL,
    payload: null,
  };
};

export const setAll = ({ vendors }: { vendors: Array<any> }) => {
  return {
    type: Types.SET_ALL,
    payload: { vendors },
  };
};

export const select = ({ id }: { id: string | null }) => {
  return {
    type: Types.SELECT,
    payload: { id },
  };
};

export const update = ({ vendor }: { vendor: any }) => {
  return {
    type: Types.UPDATE,
    payload: { vendor },
  };
};

export const create = ({ vendor }: { vendor: any }) => {
  return {
    type: Types.CREATE,
    payload: { vendor },
  };
};

export const remove = ({ id }: { id: string }) => {
  return {
    type: Types.REMOVE,
    payload: { id },
  };
};
