import { Item } from 'Api/types.generated';
import Type from 'Controller/ProductItem/ProductItem.type';

export interface ProductItemState {
  all: Array<Item>;
  selected: string | null;
}

const initalState: ProductItemState = {
  selected: null,
  all: [],
};

type Action = {
  type: Type;
  payload: {
    productItems: Array<Item>;
    id: string | null;
  };
};

export default function ProductItemReducer(
  state: ProductItemState = initalState,
  action: Action
): ProductItemState {
  const { type = '', payload } = action;

  switch (type) {
    case Type.SET_ALL: {
      const { productItems } = payload;
      return { ...state, all: productItems };
    }

    case Type.SELECT: {
      const { id } = payload;
      return { ...state, selected: id };
    }

    default:
      return state;
  }
}
