import {
  createStore,
  compose,
  combineReducers,
  applyMiddleware,
  Dispatch,
  Middleware,
} from 'redux';
import { createLogger } from 'redux-logger';
import { createBrowserHistory } from 'history';

// reducers
import MemberReducer, { MemberState } from 'Controller/Member/Member.reducer';
import ProductReducer, {
  ProductState,
} from 'Controller/Product/Product.reducer';
import ProductItemReducer, {
  ProductItemState,
} from 'Controller/ProductItem/ProductItem.reducer';
import VendorReducer, { VendorState } from 'Controller/Vendor/Vendor.reducer';
import ServiceReducer, {
  ServiceState,
} from 'Controller/Service/Service.reducer';
import NetworkReducer, {
  NetworkState,
} from 'Controller/Network/Network.reducer';
import TenantReducer, { TenantState } from 'Controller/Tenant/Tenant.reducer';
import OnboardingReducer, {
  OnboardingState,
} from 'Controller/Onboarding/Onboarding.reducer';

// middleware
import ControllerMiddleware from 'Controller/middleware';
import StandardProjectReducer, {
  StandardProjectState,
} from 'Controller/StandardProject/StandardProject.reducer';

export interface iStore {
  member: MemberState;
  product: ProductState;
  productItem: ProductItemState;
  vendor: VendorState;
  service: ServiceState;
  network: NetworkState;
  tenant: TenantState;
  onboarding: OnboardingState;
  dispatch: Dispatch;
  standardProject: StandardProjectState;
}

export const history = createBrowserHistory();

const createRootReducer = (history: any) =>
  combineReducers({
    member: MemberReducer,
    product: ProductReducer,
    productItem: ProductItemReducer,
    service: ServiceReducer,
    vendor: VendorReducer,
    network: NetworkReducer,
    tenant: TenantReducer,
    onboarding: OnboardingReducer,
    standardProject: StandardProjectReducer,
  });

// @ts-ignore
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const logger = createLogger({
  collapsed: true,
});

const store = createStore(
  createRootReducer(history),
  composeEnhancer(
    applyMiddleware(
      ControllerMiddleware,
      logger as Middleware<unknown, any, any>
    )
  )
);

export default store;
