import { useRedux } from 'Hooks';
import { Timeline, Button } from 'antd';
import { useEffect, useState } from 'react';
import { CheckCircleTwoTone } from '@ant-design/icons';
import { StackSummary, StackStatus } from '@aws-sdk/client-cloudformation';
import { StackDeployment } from 'Controller/Tenant/Tenant.type';

// export enum StackDeployment {
//   ACM_CERTIFICATE = 'deployTenantCertificateStack',
//   AD_CERTIFICATE_AUTHORITY = 'deployTenantCertificateAuthority',
//   AD_FSX = 'deployTenantActiveDirectory',
//   DIRECTORY_INFRASTRUCTURE = 'deployDirectoryInfrastructure',
//   AD_ADMINS = 'createActiveDirectoryAdmins',
// }

interface DeploymentStep {
  cfnStackNames: Array<string>;
  timelineDisplayName: string;
  cfnStacks: StackSummary[];
  deployment: string;
}

const ActiveDirectoryDeploymentTimeline = () => {
  const {
    dispatch,
    actions: {
      tenantActions: { deployStack },
    },
    store: {
      tenant: { selectedTenantStacks, workspaceDeploymentStacks },
    },
  } = useRedux();

  const activeDirectoryDeploymentSteps: Array<DeploymentStep> = [
    {
      cfnStackNames: ['directory-fsx', 'Managed-AD-and-FSx-Stack'],
      timelineDisplayName: 'Active Directory',
      cfnStacks: [],
      deployment: StackDeployment.AD_FSX,
    },
    {
      cfnStackNames: ['directory-infrastructure'],
      timelineDisplayName: 'Directory Infrastructure',
      cfnStacks: [],
      deployment: StackDeployment.DIRECTORY_INFRASTRUCTURE,
    },
    {
      cfnStackNames: [
        'ms-certificate-authority',
        'Microsoft-Enterprise-CA-Stack',
      ],
      timelineDisplayName: 'MS Certificate Authority',
      cfnStacks: [],
      deployment: StackDeployment.AD_CERTIFICATE_AUTHORITY,
    },
  ];

  const [timelineConfig, setTimelineConfig] = useState(
    activeDirectoryDeploymentSteps
  );

  useEffect(() => {
    const createdStackNames = selectedTenantStacks.filter(
      (stack) => stack.StackStatus === StackStatus.CREATE_COMPLETE
    );

    const _ = timelineConfig.map((elem) => {
      const stackMatches = createdStackNames.filter((s) => {
        const { StackName = '' } = s;

        return elem.cfnStackNames.some((stackNamePartial) =>
          StackName.toLowerCase().includes(stackNamePartial.toLowerCase())
        );
      });

      return {
        ...elem,
        cfnStacks: stackMatches,
      };
    });

    setTimelineConfig(_);
  }, [selectedTenantStacks]);

  const renderTimelineItem = () => {
    return timelineConfig.map((c) => {
      const { cfnStacks, deployment, timelineDisplayName } = c;

      const icon = cfnStacks.length > 0 && (
        <CheckCircleTwoTone
          twoToneColor="#50C878"
          style={{ fontSize: '1rem' }}
        />
      );

      const rhs =
        cfnStacks.length > 0 ? (
          <>
            <p>{cfnStacks[0].StackName}</p>
            <small>
              {new Date(cfnStacks[0].CreationTime || '').toLocaleString()}
            </small>
          </>
        ) : (
          <Button
            onClick={() => dispatch(deployStack({ deployment: deployment }))}
          >
            Deploy
          </Button>
        );

      return (
        <Timeline.Item
          key={timelineDisplayName}
          label={timelineDisplayName}
          dot={icon}
        >
          {rhs}
        </Timeline.Item>
      );
    });
  };

  return <Timeline mode={'left'}>{renderTimelineItem()}</Timeline>;
};

export default ActiveDirectoryDeploymentTimeline;
