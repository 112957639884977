import ReactDOM from 'react-dom';
import Auth from '@aws-amplify/auth';
import { Provider } from 'react-redux';
import store from 'Model';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import '@aws-amplify/ui-react/styles.css';
import 'antd/dist/reset.css';
import './styles.scss';

Auth.configure({
  mandatorySignIn: false,
  region: process.env.REACT_APP_AWS_REGION,
  userPoolId: process.env.REACT_APP_USERPOOLID,
  identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
  userPoolWebClientId: process.env.REACT_APP_USERPOOLCLIENTID,
  Analytics: {
    disabled: true,
  },
});

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root')
);

export { Auth };
