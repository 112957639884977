import { useState, useEffect } from 'react';

const useMetaFieldValidation = (metaFieldText: string) => {
  const [error, setError] = useState<string | null>('');

  useEffect(() => {
    setError(null);

    try {
      JSON.parse(metaFieldText);
    } catch (e) {
      setError(String(e));
    }
  }, [metaFieldText]);

  return [error];
};

export default useMetaFieldValidation;
