import { useState, useEffect } from 'react';
import { Product, Item, ProductItem } from 'Api/types.generated';
import { useRedux, useFilter } from 'Hooks';
import { HandleUpsertItemFunction, HandleRemoveItemFunction } from '../Items';
import {
  Divider,
  Table,
  Popconfirm,
  Button,
  Modal,
  Checkbox,
  Row,
  Input,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { ExportOutlined } from '@ant-design/icons';

interface DataType {
  key: React.Key;
  name: string;
  description: string;
  category: string;
}

interface InProductDataType extends DataType {
  quantity: number;
}

interface Values {
  id: string;
  items: Array<any>;
}

const { confirm } = Modal;

const remapToValues = (product: Product | null): Values => {
  return {
    id: product?.id || '',
    items: product?.items || [],
  };
};

const Form = ({
  product,
  handleUpsertItem,
  handleRemoveItem,
}: {
  product: Product | null;
  handleUpsertItem: HandleUpsertItemFunction;
  handleRemoveItem: HandleRemoveItemFunction;
}) => {
  const [columns, setColumns] = useState<ColumnsType<DataType>>([]);

  const [inProductColumns, setInProductColumns] = useState<
    ColumnsType<InProductDataType>
  >([]);

  const { store } = useRedux();

  const {
    product: { all, selected },
    productItem: { all: allProductItems },
  } = store;

  const [values, setValues] = useState<Values>(remapToValues(product));

  useEffect(() => {
    const _product = all.find((p) => p.id === selected);

    if (_product) {
      setValues(remapToValues(_product));
    }
  }, [selected]);

  const [newItem, setNewItem] = useState<Item | null>(null);

  const [newItemQuantity, setNewItemQuantity] = useState<number>(1);

  const { filterResults, FilterForm } = useFilter<Item>(allProductItems);

  const [selectedExistingComponent, setSelectedExistingComponent] =
    useState<Item | null>(null);

  const [existingComponents, setExistingComponents] = useState<ProductItem[]>(
    []
  );

  const [selectExisting, setSelectExisting] = useState<boolean>(false);

  useEffect(() => {
    const columns = [
      { title: 'Name', dataIndex: 'name', key: 'name' },
      {
        title: 'Category',
        dataIndex: 'category',
        key: 'category',
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
      },
    ];

    setColumns(columns);

    setInProductColumns([
      ...columns,
      {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'quantity',
      },
    ]);
  }, []);

  useEffect(() => {
    if (product) {
      const truthyProductItems = (pi): pi is ProductItem => {
        return Boolean(pi) === true;
      };

      const { items = [] } = product;

      const productItems = items?.filter(truthyProductItems) || [];

      setExistingComponents(productItems);
    }
  }, [product]);

  const showCreateNewProductItemModal = () => {
    confirm({
      title: 'Create new product component',
      content: 'Some descriptions',
      onOk() {
        console.log('OK');
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  return (
    <>
      <Divider orientation="left" orientationMargin="0">
        In product
      </Divider>
      {product && (
        <Table
          rowSelection={{
            type: 'radio',
            onChange: (e) => {
              const _selection = allProductItems.find(
                (p) => p.id === String(e)
              );

              if (_selection) {
                setSelectedExistingComponent(_selection);
              }
            },
          }}
          dataSource={existingComponents.map(({ productItem, quantity }) => {
            const { id, name, category, description } = productItem;

            return {
              key: id,
              name,
              category: String(category),
              description,
              quantity,
            };
          })}
          columns={inProductColumns}
          pagination={false}
          summary={() => (
            <Table.Summary fixed>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={24} align={'right'}>
                  <Popconfirm
                    title={'Remove from product'}
                    onConfirm={() =>
                      selectedExistingComponent &&
                      handleRemoveItem({
                        productId: values.id,
                        productItemId: selectedExistingComponent.id,
                      })
                    }
                    okText="Yes"
                    cancelText="No"
                    disabled={Boolean(selectedExistingComponent) === false}
                  >
                    <Button
                      disabled={Boolean(selectedExistingComponent) === false}
                      danger
                      type="primary"
                    >
                      {'Remove from product'}
                    </Button>
                  </Popconfirm>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )}
        />
      )}

      <Divider orientation="left" orientationMargin="0">
        Add new
      </Divider>
      <Row>
        <Button
          type="default"
          icon={<ExportOutlined style={{ bottom: 3, position: 'relative' }} />}
          onClick={showCreateNewProductItemModal}
          style={{ margin: '1rem 0 1rem' }}
        >
          Create new product component
        </Button>
      </Row>
      <Row>
        <Checkbox
          style={{ margin: '1rem 0 1rem' }}
          checked={selectExisting}
          onChange={(e) => {
            setSelectExisting(e.target.checked);
          }}
        >
          Select existing product component
        </Checkbox>
      </Row>
      {selectExisting && (
        <>
          <FilterForm />
          <Table
            rowSelection={{
              type: 'radio',
              onChange: (e) => {
                const _selection = allProductItems.find(
                  (p) => p.id === String(e)
                );

                if (_selection) {
                  setNewItem(_selection);
                }
              },
            }}
            dataSource={filterResults.map((fr) => {
              const { id, name, category, description } = fr;

              return {
                key: id,
                name,
                category: String(category),
                description,
              };
            })}
            columns={columns}
            summary={() => (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={24} align={'right'}>
                    <Input
                      style={{ maxWidth: '200px', marginRight: '1rem' }}
                      addonBefore="Quantity"
                      type="number"
                      min="1"
                      max="100"
                      value={newItemQuantity}
                      disabled={Boolean(newItem) === false}
                      onChange={(e) =>
                        setNewItemQuantity(parseInt(e.target.value))
                      }
                    />
                    <Popconfirm
                      title={'Add'}
                      onConfirm={() =>
                        newItem &&
                        values &&
                        handleUpsertItem({
                          productId: values.id,
                          productItemId: newItem.id,
                          quantity: newItemQuantity,
                        })
                      }
                      okText="Yes"
                      cancelText="No"
                      disabled={Boolean(newItem) === false}
                    >
                      <Button
                        disabled={Boolean(newItem) === false}
                        type="primary"
                      >
                        {`Add to product ${values.id}`}
                      </Button>
                    </Popconfirm>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            )}
          />
        </>
      )}
    </>
  );
};

export default Form;
