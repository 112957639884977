import { Table } from 'antd';
import { useRedux, useFilter } from 'Hooks';
import { Vendor } from 'Api/types.generated';
import React, { useEffect, useState } from 'react';
import type { ColumnsType } from 'antd/es/table';

interface DataType {
  key: React.Key;
  name: string;
}

const VendorList = ({
  handleSelection,
}: {
  handleSelection: (e: any) => void;
}) => {
  const [columns, setColumns] = useState<ColumnsType<DataType>>([]);

  const { store } = useRedux();

  const {
    vendor: { all, selected },
  } = store;

  const { filterResults, FilterForm } = useFilter<Vendor>(all);

  useEffect(() => {
    setColumns([{ title: 'Name', dataIndex: 'name', key: 'name' }]);
  }, []);

  return (
    <React.Fragment>
      <FilterForm />
      <Table
        rowSelection={{
          type: 'radio',
          onChange: handleSelection,
        }}
        dataSource={filterResults.map((fr) => {
          const { id, name } = fr;

          return {
            key: id,
            name,
          };
        })}
        columns={columns}
      />
    </React.Fragment>
  );
};

export default VendorList;
