import React, { useState, useEffect, Fragment } from 'react';
import { json2csv } from 'json-2-csv';
import {
  BillingAwsCosts,
  BillingDeployment,
  CostsByUseType,
  BillingProject,
  BillingService,
} from 'Api/types.generated';
import { ResponsivePie } from '@nivo/pie';
import {
  Statistic,
  Row,
  Col,
  Divider,
  Space,
  Tabs,
  Table,
  FloatButton,
} from 'antd';
import { CollapsableCard } from 'tyrell-common-libs-web';
import type { TabsProps } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { DownloadOutlined } from '@ant-design/icons';

// round to two decimal places
export const r2 = (numero: number) =>
  Math.round((numero + Number.EPSILON) * 100) / 100;

const generateDeploymentTable = (
  deployments: BillingDeployment[] | undefined
) => {
  interface DataType {
    key: React.Key;
    ID: string;
    name: string;
    productName: string;
    gross: number;
    net: number;
    tax: number;
    volumes: number;
    storage: number;
    servers: number;
    requests: number;
    miscellaneous: number;
    loadBalancer: number;
    ingress: number;
    egress: number;
    removed: Date | null;
    runningTotal: number;
  }

  if (!deployments) {
    return null;
  }

  const data: DataType[] = deployments.map((d) => {
    return {
      key: d.id,
      name: d.name,
      productName: d.productName,
      runningTotal: d.runningTotal,
      ID: d.id,
      gross: d.costsByUseType.gross,
      net: d.costsByUseType.net,
      tax: d.costsByUseType.tax,
      volumes: d.costsByUseType.volumes,
      storage: d.costsByUseType.storage,
      servers: d.costsByUseType.servers,
      requests: d.costsByUseType.requests,
      miscellaneous: d.costsByUseType.miscellaneous,
      loadBalancer: d.costsByUseType.loadBalancer,
      ingress: d.costsByUseType.ingress,
      egress: d.costsByUseType.egress,
      removed: d.removedAt ? new Date(d.removedAt) : null,
    };
  });

  const render = (v: number) => {
    if (v === 0) {
      return <p style={{ opacity: 0.5 }}>-</p>;
    }
    return `$${v}`;
  };

  const columns: ColumnsType<DataType> = [
    {
      title: 'Name',
      width: 150,
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
    },
    {
      title: 'Product',
      width: 150,
      dataIndex: 'productName',
      key: 'productName',
      fixed: 'left',
    },
    {
      title: 'Gross',
      dataIndex: 'gross',
      key: 'gross',
      render,
    },
    {
      title: 'net',
      dataIndex: 'net',
      key: 'net',
      render,
    },
    {
      title: 'tax',
      dataIndex: 'tax',
      key: 'tax',
      render,
    },
    {
      title: 'Running',
      width: 100,
      dataIndex: 'runningTotal',
      key: 'runningTotal',
      render: (v: number) => {
        return <p>{`${v} hours`}</p>;
      },
    },
    {
      title: 'volumes',
      dataIndex: 'volumes',
      key: 'volumes',
      render,
    },
    {
      title: 'storage',
      dataIndex: 'storage',
      key: 'storage',
      render,
    },
    {
      title: 'servers',
      dataIndex: 'servers',
      key: 'servers',
      render,
    },
    {
      title: 'requests',
      dataIndex: 'requests',
      key: 'requests',
      render,
    },
    {
      title: 'miscellaneous',
      dataIndex: 'miscellaneous',
      key: 'miscellaneous',
      render,
    },
    {
      title: 'loadBalancer',
      dataIndex: 'loadBalancer',
      key: 'loadBalancer',
      render,
    },
    {
      title: 'ingress',
      dataIndex: 'ingress',
      key: 'ingress',
      render,
    },
    {
      title: 'egress',
      dataIndex: 'egress',
      key: 'egress',
      render,
    },
    {
      title: 'ID',
      width: 350,
      dataIndex: 'ID',
      key: 'ID',
    },
    {
      title: 'Removed',
      width: 200,
      dataIndex: 'removed',
      key: 'removed',
      render: (v: Date | null) => {
        return v ? (
          <p>{v.toDateString()}</p>
        ) : (
          <i style={{ opacity: 0.5 }}>Currently deployed</i>
        );
      },
    },
  ];

  return <Table columns={columns} dataSource={data} scroll={{ x: 2500 }} />;
};

const renderCostUseByTypeElement = (
  costsByUseType: CostsByUseType,
  showTax: boolean = false
) => {
  const { __typename, gross, net, tax, ...rest } = costsByUseType;

  return (
    <>
      <Row>
        <Col span={6}>
          <Statistic title={'NET'} value={r2(net)} prefix="$" />
        </Col>
        {!!tax && tax !== 0 && showTax && (
          <>
            <Col span={6}>
              <Statistic title={'TAX'} value={r2(tax)} prefix="$" />
            </Col>
            <Col span={6}>
              <Statistic title={'GROSS'} value={r2(gross)} prefix="$" />
            </Col>
          </>
        )}
      </Row>
      {net !== 0 && (
        <>
          <Divider />
          <Row>
            {Object.entries(rest).map((gut) => {
              const [key, value] = gut;

              return (
                <Col span={6} key={`${key}-${value}`}>
                  <Statistic
                    title={key.toUpperCase()}
                    value={r2(value)}
                    prefix="$"
                  />
                </Col>
              );
            })}
          </Row>
        </>
      )}
    </>
  );
};

const renderPie = (costByUseType: CostsByUseType, showTax: boolean = false) => {
  const { __typename, net, gross, ...rest } = costByUseType;

  if (net === 0) {
    return null;
  }

  interface PieData {
    id: string;
    label: string;
    value: number;
    color: string;
  }

  const hideTax = (d: PieData) => {
    if (showTax === false) {
      return d.id !== 'tax';
    } else {
      return d;
    }
  };

  const data: PieData[] = Object.entries(rest)
    .map((el) => {
      const [key, value] = el;

      return {
        id: key,
        label: key.toUpperCase(),
        value: r2(value),
        color: `hsl(${Math.floor(Math.random() * 256)}, 70%, 50%)`,
      };
    })
    .filter(hideTax);

  return (
    <Row>
      <div style={{ width: '100%', height: '400px' }}>
        <ResponsivePie
          data={data}
          margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
          innerRadius={0.5}
          padAngle={0.7}
          cornerRadius={3}
          activeOuterRadiusOffset={8}
          borderWidth={1}
          borderColor={{
            from: 'color',
            modifiers: [['darker', 0.2]],
          }}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextColor="#333333"
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: 'color' }}
          arcLabelsSkipAngle={10}
          arcLabelsTextColor={{
            from: 'color',
            modifiers: [['darker', 2]],
          }}
        />
      </div>
    </Row>
  );
};

const AwsCosts = ({ awsCosts }: { awsCosts: BillingAwsCosts }) => {
  const [csvUrl, setCsvUrl] = useState<string>('');

  const handleJsonToCsvConvert = (_awsCosts: BillingAwsCosts) => {
    const allDeployments = _awsCosts.projects
      .map((p) => {
        if (!p) {
          return [];
        }

        return Object.values(p.services)
          .map((s: any) => s.deployments)
          .flat();
      })
      .flat()
      .filter((d) => !!d)
      .map((d: BillingDeployment) => {
        const {
          __typename,
          costsByUseType: { __typename: tn, ...costsByUseTypeRest },
          ...deploymentRest
        } = d;

        return { ...deploymentRest, ...costsByUseTypeRest };
      });

    const { __typename, ...workspaceCostsRest } =
      _awsCosts.workspace.costsByUseType;

    const paddedValues = [
      'id',
      'productName',
      'projectName',
      'deployedAt',
      'deployedBy',
      'productId',
      'vendorName',
      'removed',
      'removedAt',
      'removedBy',
    ].reduce((a, c) => {
      return { ...a, [c]: '-' };
    }, {});

    const workspaceRow = {
      name: 'Workspace',
      tenantName: _awsCosts.tenant.name,
      serviceName: 'Workspace',
      ...paddedValues,
      ...workspaceCostsRest,
    };

    json2csv([workspaceRow, ...allDeployments], (err, csv) => {
      if (csv) {
        const blob = new Blob([csv]);
        const fileDownloadUrl = URL.createObjectURL(blob);
        setCsvUrl(fileDownloadUrl);
      }
    });
  };

  useEffect(() => {
    if (awsCosts) {
      handleJsonToCsvConvert(awsCosts);
    }
  }, [awsCosts]);

  return (
    awsCosts && (
      <div
        className="Billing__aws-cost-container"
        style={{ margin: '0 auto', maxWidth: 762 }}
      >
        <Space
          style={{
            display: 'flex',
            flexDirection: 'column',
            margin: '0 auto 3rem',
          }}
          size="large"
        >
          <Divider>
            <h3>Tenant</h3>
          </Divider>

          <CollapsableCard title={`${awsCosts.tenant.name} total`} width={1000}>
            <Fragment>
              {renderCostUseByTypeElement(awsCosts.tenant.costsByUseType)}
              <Divider />
              {renderPie(awsCosts.tenant.costsByUseType)}
            </Fragment>
          </CollapsableCard>

          <CollapsableCard title="Workspace" width={1000}>
            <Fragment>
              {renderCostUseByTypeElement(awsCosts.workspace.costsByUseType)}
              <Divider />
              {renderPie(awsCosts.workspace.costsByUseType)}
            </Fragment>
          </CollapsableCard>

          <Divider>
            <h3>Projects</h3>
          </Divider>

          {awsCosts.projects
            .filter((p): p is BillingProject => {
              return !!p;
            })
            .map((p) => {
              const { workstation, connect, capture, store, transfer } =
                p.services;

              const items: TabsProps['items'] = Object.entries({
                workstation,
                connect,
                capture,
                store,
                transfer,
              }).map((s, idx) => {
                const [serviceName, service] = s as [string, BillingService];

                const { deployments } = service;

                return {
                  key: `${idx}`,
                  label: serviceName,
                  // @ts-ignore
                  children: generateDeploymentTable(deployments),
                };
              });

              return (
                <CollapsableCard title={p.name} width={1000}>
                  <Fragment>
                    {renderCostUseByTypeElement(p.costsByUseType)}
                    <Divider />
                    {renderPie(p.costsByUseType)}
                    <Divider style={{ opacity: 0 }} />
                    {p.costsByUseType.net !== 0 && (
                      <Tabs defaultActiveKey="1" items={items} />
                    )}
                  </Fragment>
                </CollapsableCard>
              );
            })}

          <FloatButton
            shape="circle"
            type="primary"
            tooltip="Download as CSV"
            style={{ right: 50 }}
            icon={
              <DownloadOutlined style={{ position: 'relative', bottom: 5 }} />
            }
            onClick={() => {
              const dl = document.getElementById('csv-download-link');
              if (dl) {
                dl.click();
              }
            }}
          />
          <a
            style={{ opacity: 0 }}
            id={'csv-download-link'}
            download={`${awsCosts.tenant.awsAccountId}.csv`}
            href={csvUrl}
          />
        </Space>
      </div>
    )
  );
};

export default AwsCosts;
