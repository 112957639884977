import { useRedux } from 'Hooks';
import Form from 'Components/Member/Form';

const Detail = () => {
  const { dispatch, actions, store } = useRedux();

  const {
    memberActions: { update, remove },
  } = actions;

  const {
    member: { all, selected },
  } = store;

  const member = all.find((m) => m.id === selected);

  const handleSubmit = (values: any) => {
    dispatch(update({ member: values }));
  };

  const handleDelete = (id: string) => {
    dispatch(remove({ id }));
  };

  return (
    <Form
      member={member || null}
      handleSubmit={handleSubmit}
      handleDelete={handleDelete}
    />
  );
};

export default Detail;
