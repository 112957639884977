import { useState, useEffect, Fragment } from 'react';
import { useRedux } from 'Hooks';
import { Form, Input, Select, Checkbox, Button } from 'antd';
import { PageContainer, CollapsableCard } from '../../common';
import AddressFormCard, { Address } from './address';

const { Option } = Select;
const { Item } = Form;

const TenantCreateNew = () => {
  const {
    dispatch,
    actions: {
      tenantActions: { createNew },
    },
    store: {
      member: { all: allMembers },
    },
  } = useRedux();

  const [dryRunMode, setDryRunMode] = useState(false);
  const [tenantName, setTenantName] = useState('');
  const [tenantShortName, setShortName] = useState('');
  const [shortNameError, setShortNameError] = useState(false);
  const [displayedAsName, setDisplayedAsName] = useState('');
  const [ownerSelected, setOwnerSelected] = useState(null);
  const [selectExistingMember, setSelectExistingMember] = useState(false);
  const [ownerFirstName, setOwnerFirstName] = useState('');
  const [ownerLastName, setOwnerLastName] = useState('');
  const [ownerEmail, setOwnerEmail] = useState('');
  const [ownerPhone, setOwnerPhone] = useState('');
  const [formIsValid, setFormIsValid] = useState(false);
  const [maxSlugLength, setMaxSlugLength] = useState(0);
  const [address, setAddress] = useState<Address>({
    addressLine1: '',
    addressLine2: '',
    city: '',
    country: '',
    postcode: '',
  });

  useEffect(() => {
    const env = process.env.REACT_APP_TYRELL_ENV || 'dev';
    const maxSlugLength = env.toLowerCase() === 'prod' ? 15 : 11;
    setMaxSlugLength(maxSlugLength);
  }, []);

  useEffect(() => {
    if (tenantShortName) {
      const env = process.env.REACT_APP_TYRELL_ENV || 'dev';
      const slug = tenantShortName.replace(/[^a-z0-9]/gi, '').toLowerCase();

      if (slug.length > maxSlugLength) {
        setShortNameError(true);
      } else {
        setShortNameError(false);
        setDisplayedAsName(slug);
      }
    } else {
      setDisplayedAsName('');
    }
  }, [tenantShortName]);

  useEffect(() => {
    if (!ownerSelected) return;

    const member = allMembers.find((m) => m.id === ownerSelected);

    if (!member) return;

    setOwnerFirstName(member.givenName);
    setOwnerLastName(member.familyName);
    setOwnerEmail(member.email);
    setOwnerPhone(member.phoneNumber);
  }, [ownerSelected]);

  useEffect(() => {
    setFormIsValid(
      Boolean(tenantName) &&
        Boolean(tenantShortName) &&
        Boolean(displayedAsName) &&
        Boolean(ownerFirstName) &&
        Boolean(ownerLastName) &&
        Boolean(ownerEmail) &&
        Boolean(ownerPhone) &&
        Boolean(address)
    );
  }, [
    tenantName,
    tenantShortName,
    displayedAsName,
    ownerFirstName,
    ownerLastName,
    ownerEmail,
    ownerPhone,
    address,
  ]);

  const handleSubmit = () => {
    dispatch(
      createNew({
        tenantName,
        tenantShortName: displayedAsName,
        phoneNumber: ownerPhone,
        givenName: ownerFirstName,
        familyName: ownerLastName,
        email: ownerEmail,
        ownerId: ownerSelected,
        addressLine1: address.addressLine1,
        addressLine2: address.addressLine2,
        city: address.city,
        country: address.country,
        postcode: address.postcode,
        isDryRun: dryRunMode,
      })
    );
  };

  return (
    <PageContainer title="Create new tenant">
      <Fragment>
        <CollapsableCard title="Name">
          <Form
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 14 }}
            layout="horizontal"
          >
            <Item required label="Name">
              <Input
                value={tenantName}
                onChange={(e) => setTenantName(e.target.value)}
              />
            </Item>
            <Item
              required
              label="Short name"
              hasFeedback={shortNameError}
              validateStatus={shortNameError ? 'error' : ''}
              help={`Max ${maxSlugLength} characters`}
            >
              <Input
                value={tenantShortName}
                addonAfter={`${tenantShortName.length}`}
                onChange={(e) => {
                  const shortName = e.target.value;
                  if (shortName.length <= maxSlugLength) {
                    setShortName(shortName);
                  }
                }}
              />
            </Item>
            <Item
              required
              label="Displayed as"
              help={'Remove anything not alphanumeric'}
            >
              <Input disabled value={displayedAsName} />
            </Item>
          </Form>
        </CollapsableCard>
        <CollapsableCard title="Owner">
          <Form
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 14 }}
            layout="horizontal"
          >
            <Item label="Existing member">
              <Checkbox
                checked={selectExistingMember}
                onChange={(e) => setSelectExistingMember(e.target.checked)}
              />
            </Item>
            {selectExistingMember && (
              <Item label="Owner">
                <Select
                  allowClear
                  defaultValue={null}
                  value={ownerSelected}
                  onChange={(id) => setOwnerSelected(id)}
                >
                  {allMembers.map((m) => (
                    <Option
                      key={m.id}
                      value={m.id}
                    >{`${m.givenName} ${m.familyName}`}</Option>
                  ))}
                </Select>
              </Item>
            )}
            <Item label="First name" required>
              <Input
                disabled={selectExistingMember}
                value={ownerFirstName}
                onChange={(e) => setOwnerFirstName(e.target.value)}
              />
            </Item>
            <Item label="Last name" required>
              <Input
                disabled={selectExistingMember}
                value={ownerLastName}
                onChange={(e) => setOwnerLastName(e.target.value)}
              />
            </Item>
            <Item label="Email" required>
              <Input
                disabled={selectExistingMember}
                value={ownerEmail}
                onChange={(e) => setOwnerEmail(e.target.value)}
              />
            </Item>
            <Item label="Phone" required>
              <Input
                disabled={selectExistingMember}
                value={ownerPhone}
                onChange={(e) => setOwnerPhone(e.target.value)}
              />
            </Item>
          </Form>
        </CollapsableCard>
        <AddressFormCard handleUpdate={setAddress} initialAddress={address} />
        <CollapsableCard title="Actions">
          <Fragment>
            <Form
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 14 }}
              layout="horizontal"
            >
              {process.env.REACT_APP_TYRELL_ENV == 'dev' && (
                <Item label="Dry run mode">
                  <Checkbox
                    checked={dryRunMode}
                    onChange={(e) => setDryRunMode(e.target.checked)}
                  />
                </Item>
              )}
              <Item label="Submit">
                <Button disabled={!formIsValid} onClick={handleSubmit}>
                  Create New Tenant
                </Button>
              </Item>
            </Form>
          </Fragment>
        </CollapsableCard>
      </Fragment>
    </PageContainer>
  );
};

export { TenantCreateNew };
