import List from 'Components/Vendor/List';
import { Detail, New } from 'Components/Vendor/Form';
import ProductItem from 'Components/Vendor/Products';
import { useRedux } from 'Hooks';
import { Space, Card, Typography } from 'antd';
import { useState } from 'react';
import _get from 'lodash.get';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

const VendorContainer = () => {
  const [showSearch, setShowSearch] = useState(true);
  const [showDetails, setShowDetails] = useState(true);
  const [showProducts, setShowProducts] = useState(true);

  const { dispatch, actions, store } = useRedux();

  const {
    vendorActions: { select },
  } = actions;

  const {
    vendor: { all, selected },
  } = store;

  const handleTableSelection = (selectedRowKeys: React.Key[]) => {
    const _selection = all.find((p) => p.id === String(selectedRowKeys));
    _selection && dispatch(select({ id: _selection.id }));
    document.getElementById('product-configuration-form')?.scrollIntoView();
    setShowDetails(true);
  };

  return (
    <Space
      style={{
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
      }}
      size="large"
    >
      <Typography.Title level={2} style={{ width: 762, textAlign: 'left' }}>
        Vendor
      </Typography.Title>
      <Card
        title="Search"
        style={{ width: 762 }}
        extra={
          showSearch ? (
            <DownOutlined onClick={() => setShowSearch(false)} />
          ) : (
            <UpOutlined onClick={() => setShowSearch(true)} />
          )
        }
        bodyStyle={{ display: showSearch ? 'block' : 'none' }}
      >
        <List handleSelection={handleTableSelection} />
      </Card>

      <Card
        title="Configuration"
        style={{ width: 762 }}
        id={'product-configuration-form'}
        extra={
          showDetails ? (
            <DownOutlined onClick={() => setShowDetails(false)} />
          ) : (
            <UpOutlined onClick={() => setShowDetails(true)} />
          )
        }
        bodyStyle={{ display: showDetails ? 'block' : 'none' }}
      >
        {selected ? <Detail /> : <New />}
      </Card>
      {selected && (
        <Card
          title="Products"
          style={{ width: 762 }}
          extra={
            showProducts ? (
              <DownOutlined onClick={() => setShowProducts(false)} />
            ) : (
              <UpOutlined onClick={() => setShowProducts(true)} />
            )
          }
          bodyStyle={{ display: showProducts ? 'block' : 'none' }}
        >
          <ProductItem />
        </Card>
      )}
    </Space>
  );
};

export default VendorContainer;
