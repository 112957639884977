import { Fragment, useEffect, useState } from 'react';
import { useRedux } from 'Hooks';
import {
  Form,
  Select,
  Input,
  Button,
  Result,
  Upload,
  Space,
  message,
  Row,
  Col,
  Alert,
} from 'antd';
import {
  DeleteOutlined,
  UploadOutlined,
  UserAddOutlined,
  SendOutlined,
} from '@ant-design/icons';
import { Project } from 'Api/types.generated';
import { InvitationRequest } from 'Controller/Onboarding/Onboarding.type';
import {
  PageContainer,
  CollapsableCard,
  iconStyleOffset,
} from 'Components/common';
import CsvToJson from 'csvtojson';
import InvitesTable from './table';

const { Item } = Form;
const { Option } = Select;

const BLANK_INVITATION_SCHEMA = {
  email: '',
  roleId: 4,
};

export const ROLES = {
  ADMIN: 2,
  OPS: 3,
  USER: 4,
};

export const OnboardingInvitations = () => {
  const [projectOptions, setProjectOptions] = useState<Array<Project>>([]);

  const [selectedProject, setSelectedProject] = useState<number | null>(null);

  const [invitations, setInvitations] = useState<Array<InvitationRequest>>([
    BLANK_INVITATION_SCHEMA,
  ]);

  const {
    dispatch,
    actions: {
      tenantActions: { select: selectTenant },
      onboardingActions: { invite, setSendStatus },
    },
    store: {
      onboarding: { sendStatus },
      tenant: { selected: selectedTenant, all: allTenants },
    },
  } = useRedux();

  useEffect(() => {
    if (selectedTenant) {
      const thisTenant = allTenants.find((t) => t.id === selectedTenant);
      const projectOptions = (thisTenant &&
        thisTenant.projects) as Array<Project>;

      if (projectOptions) {
        setProjectOptions(projectOptions);
      }
    }
  }, [selectedTenant]);

  /**
   *
   * Invitation form handlers
   */

  const addInvitationElement = () => {
    setInvitations([...invitations, BLANK_INVITATION_SCHEMA]);
  };

  const removeInvitationElement = (indexToRemove: number) => {
    const updated = [
      ...invitations.slice(0, indexToRemove),
      ...invitations.slice(indexToRemove + 1),
    ];

    setInvitations(updated);
  };

  const updateInvitationEmail = ({
    email,
    index,
  }: {
    email: string;
    index: number;
  }) => {
    let updated = [...invitations];
    updated[index].email = email;
    setInvitations(updated);
  };

  const updateInvitationRole = ({
    roleId,
    index,
  }: {
    roleId: number;
    index: number;
  }) => {
    setInvitations((existing) => {
      let copy = [...existing];
      copy[index].roleId = roleId;
      return copy;
    });
  };

  const handleInvite = () => {
    if (!selectedProject) {
      message.error('You must select a project to invite members to');
    } else {
      const projectId = selectedProject as number;
      dispatch(invite({ projectId, invitations }));
    }
  };

  const handleCsvUpload = (file) => {
    const reader = new FileReader();

    reader.onload = async (e) => {
      const csvText = e.target?.result?.toString() || '';
      const csvArray = await CsvToJson().fromString(csvText);

      const invitationsWithRoleAsId = csvArray.map((invite) => {
        const { email, role } = invite;
        return {
          email,
          roleId: ROLES[role.toUpperCase()],
        };
      });

      setInvitations(invitationsWithRoleAsId);
    };

    reader.readAsText(file);
    return false;
  };

  /**
   *
   * Result component
   */

  const sendStatusResult = null;

  return (
    <PageContainer title="Project invitations">
      <Fragment>
        <CollapsableCard title="Select project">
          <Form
            style={{ width: '100%' }}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 14 }}
            layout="horizontal"
          >
            <Item label="Tenant">
              <Select
                allowClear
                defaultValue={null}
                value={selectedTenant}
                onChange={(id) => dispatch(selectTenant({ id }))}
              >
                {allTenants.map((t) => (
                  <Option
                    key={t.id}
                    value={t.id}
                  >{`${t.name} - ${t.awsAccountId}`}</Option>
                ))}
              </Select>
            </Item>
            <Item label="Project">
              <Select
                disabled={!selectedTenant}
                allowClear
                defaultValue={null}
                value={selectedProject}
                onChange={(id) => setSelectedProject(id)}
              >
                {projectOptions.map((p) => (
                  <Option key={p.name} value={p.id}>{`${p.name}`}</Option>
                ))}
              </Select>
            </Item>
          </Form>
        </CollapsableCard>
        <CollapsableCard
          title="Invite"
          actions={[
            <Upload accept=".csv" beforeUpload={handleCsvUpload}>
              <Button
                type="link"
                icon={<UploadOutlined style={iconStyleOffset} />}
              >
                Upload CSV
              </Button>
            </Upload>,
            <Button
              icon={<UserAddOutlined style={iconStyleOffset} />}
              type="link"
              onClick={addInvitationElement}
            >
              Add input field
            </Button>,
            <Button
              disabled={
                invitations.length === 0 || invitations.some((i) => !i.email)
              }
              icon={<SendOutlined style={iconStyleOffset} />}
              type="link"
              onClick={handleInvite}
            >
              Send Invitations
            </Button>,
          ]}
        >
          <Form
            style={{ width: '100%' }}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 14 }}
            layout="horizontal"
          >
            <Space
              style={{
                display: 'flex',
                flexDirection: 'column',
                margin: '0 auto',
                width: '100%',
              }}
              size="large"
            >
              {sendStatus === true && (
                <Result
                  status="success"
                  title="Successfully sent invitation emails"
                  subTitle="These will show up in the invitation list immediately"
                  extra={[
                    <Button
                      type="primary"
                      key="console"
                      onClick={() =>
                        dispatch(setSendStatus({ sendStatus: null }))
                      }
                    >
                      Send more invitations
                    </Button>,
                  ]}
                />
              )}
              {sendStatus === false && (
                <Result
                  status="error"
                  title="Failed to send the invitation email"
                  subTitle="Please try again"
                  extra={[
                    <Button
                      type="primary"
                      key="console"
                      onClick={() =>
                        dispatch(setSendStatus({ sendStatus: null }))
                      }
                    >
                      Try again
                    </Button>,
                  ]}
                />
              )}
              {sendStatus === null && (
                <Fragment>
                  {invitations.map((invitation, index) => {
                    return (
                      <Input.Group
                        style={{ width: '550px' }}
                        key={`email-input-field-${index}`}
                        compact
                      >
                        <Input
                          style={{ width: '50%' }}
                          defaultValue={''}
                          value={invitation.email}
                          placeholder="Email"
                          onChange={(e) =>
                            updateInvitationEmail({
                              email: e.target.value,
                              index,
                            })
                          }
                        />
                        <Select
                          defaultValue={ROLES.USER}
                          value={invitation.roleId}
                          style={{ width: '30%' }}
                          onChange={(value) => {
                            updateInvitationRole({ roleId: value, index });
                          }}
                        >
                          {Object.entries(ROLES).map((r) => {
                            const [name, seniority] = r;
                            return (
                              <Option key={`${name}`} value={seniority}>
                                {name}
                              </Option>
                            );
                          })}
                        </Select>
                        <Button
                          type="link"
                          danger
                          style={{ width: '20%' }}
                          icon={<DeleteOutlined style={iconStyleOffset} />}
                          onClick={() => removeInvitationElement(index)}
                        >
                          Delete
                        </Button>
                      </Input.Group>
                    );
                  })}
                  <Alert
                    message="CSV column format is 'email', 'role'"
                    description="Role options are 'admin', 'ops' or 'user'"
                    type="info"
                    showIcon
                    style={{ width: '100%' }}
                  />
                </Fragment>
              )}
            </Space>
          </Form>
        </CollapsableCard>
        {selectedProject && (
          <CollapsableCard title="Invitees">
            <InvitesTable selectedProject={selectedProject} />
          </CollapsableCard>
        )}
      </Fragment>
    </PageContainer>
  );

  // return (
  //   <div className="OnboardingInvitations">
  //     <h2>Project member invitation</h2>
  //     <Card title="Invite to project">
  //       <Form
  //         labelCol={{ span: 4 }}
  //         wrapperCol={{ span: 14 }}
  //         layout="horizontal"
  //       >
  //         <Item label="Tenant">
  //           <Select
  //             allowClear
  //             defaultValue={null}
  //             value={selectedTenant}
  //             onChange={(id) => dispatch(selectTenant({ id }))}
  //           >
  //             {allTenants.map((t) => (
  //               <Option
  //                 key={t.id}
  //                 value={t.id}
  //               >{`${t.name} - ${t.awsAccountId}`}</Option>
  //             ))}
  //           </Select>
  //         </Item>
  //         <Item label="Project">
  //           <Select
  //             disabled={!selectedTenant}
  //             allowClear
  //             defaultValue={null}
  //             value={selectedProject}
  //             onChange={(id) => setSelectedProject(id)}
  //           >
  //             {projectOptions.map((p) => (
  //               <Option key={p.name} value={p.id}>{`${p.name}`}</Option>
  //             ))}
  //           </Select>
  //         </Item>
  //         {sendStatus === true && (
  //           <Result
  //             status="success"
  //             title="Successfully sent invitation emails"
  //             subTitle="These will show up in the invitation list immediately"
  //             extra={[
  //               <Button
  //                 type="primary"
  //                 key="console"
  //                 onClick={() => dispatch(setSendStatus({ sendStatus: null }))}
  //               >
  //                 Send more invitations
  //               </Button>,
  //             ]}
  //           />
  //         )}
  //         {sendStatus === false && (
  //           <Result
  //             status="error"
  //             title="Failed to send the invitation email"
  //             subTitle="Please try again"
  //             extra={[
  //               <Button
  //                 type="primary"
  //                 key="console"
  //                 onClick={() => dispatch(setSendStatus({ sendStatus: null }))}
  //               >
  //                 Try again
  //               </Button>,
  //             ]}
  //           />
  //         )}
  //         {sendStatus === null && (
  //           <>
  //             <Divider orientation="left" style={{ margin: '2rem 0' }}>
  //               Invitations
  //             </Divider>
  //             <div className="invitation-form-container">
  //               {invitations.map((invitation, index) => {
  //                 return (
  //                   <Input.Group
  //                     key={`email-input-field-${index}`}
  //                     compact
  //                     className="invitation-input"
  //                   >
  //                     <Input
  //                       style={{ width: '60%' }}
  //                       defaultValue={''}
  //                       value={invitation.email}
  //                       placeholder="Email"
  //                       onChange={(e) =>
  //                         updateInvitationEmail({
  //                           email: e.target.value,
  //                           index,
  //                         })
  //                       }
  //                     />
  //                     <Select
  //                       defaultValue={ROLES.USER}
  //                       value={invitation.roleId}
  //                       style={{ width: '30%' }}
  //                       onChange={(value) => {
  //                         updateInvitationRole({ roleId: value, index });
  //                       }}
  //                     >
  //                       {Object.entries(ROLES).map((r) => {
  //                         const [name, seniority] = r;
  //                         return (
  //                           <Option key={`${name}`} value={seniority}>
  //                             {name}
  //                           </Option>
  //                         );
  //                       })}
  //                     </Select>
  //                     <Button
  //                       type="text"
  //                       danger
  //                       style={{ width: '10%' }}
  //                       onClick={() => removeInvitationElement(index)}
  //                     >
  //                       <DeleteOutlined />
  //                     </Button>
  //                   </Input.Group>
  //                 );
  //               })}
  //               <Alert
  //                 message="CSV Format"
  //                 description="Ensure columns follow the format 'email,role' "
  //                 type="info"
  //                 showIcon
  //                 style={{ marginBottom: '1rem' }}
  //               />
  //               <div className="invitiation-actions">
  //                 <Button
  //                   size="large"
  //                   shape="circle"
  //                   icon={<UserAddOutlined />}
  //                   onClick={addInvitationElement}
  //                 />
  //                 <Upload accept=".csv" beforeUpload={handleCsvUpload}>
  //                   <Button size="large" icon={<UploadOutlined />}>
  //                     Upload CSV
  //                   </Button>
  //                 </Upload>
  //                 <Button
  //                   disabled={
  //                     invitations.length === 0 ||
  //                     invitations.some((i) => !i.email)
  //                   }
  //                   icon={<SendOutlined />}
  //                   type="primary"
  //                   size="large"
  //                   onClick={handleInvite}
  //                 >
  //                   Send Invitations
  //                 </Button>
  //               </div>
  //             </div>
  //           </>
  //         )}
  //       </Form>
  //     </Card>

  //     {selectedProject && (
  //       <Card title="Currrent invitees">
  //         <InvitesTable selectedProject={selectedProject} />
  //       </Card>
  //     )}
  //   </div>
  // );
};
