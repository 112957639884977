import { Fragment } from 'react';
import { useRedux } from 'Hooks';
import List from 'Components/Member/List';
import Detail from 'Components/Member/Detail';
import New from 'Components/Member/New';
import {
  ProjectMemberRoleTable,
  TenantMemberRoleTable,
} from 'Components/Member/MemberRole';
import { PageContainer, CollapsableCard } from 'Components/common';

const MemberContainer = () => {
  const { dispatch, actions, store } = useRedux();

  const {
    memberActions: { select },
  } = actions;

  const {
    member: { all },
  } = store;

  const handleTableSelection = (selectedRowKeys: React.Key[]) => {
    const _selection = all.find((p) => p.id === String(selectedRowKeys));
    _selection && dispatch(select({ id: _selection.id }));
  };

  return (
    <PageContainer title="Members">
      <Fragment>
        <CollapsableCard title="Search">
          <List handleSelection={handleTableSelection} />
        </CollapsableCard>
        <CollapsableCard title="Details">
          <Detail />
        </CollapsableCard>
        <CollapsableCard title="Tenants">
          <TenantMemberRoleTable />
        </CollapsableCard>
        <CollapsableCard title="Projects">
          <ProjectMemberRoleTable />
        </CollapsableCard>
      </Fragment>
    </PageContainer>
  );
};

export default MemberContainer;
