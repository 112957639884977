import { Domain } from 'tyrell-common-libs-node';

export type StandardProjectListItem_DTO =
  Domain.Project.StandardProjectListItem_DTO;

export type StandardProjectRequestStatus =
  Domain.Project.StandardProjectRequestStatus;

enum Types {
  LIST_ALL = '[STD-PROJECT] LIST_ALL',
  SET_ALL = '[STD-PROJECT] SET_ALL',

  SET_FILTER = '[STD-PROJECT] SET_FILTER',

  SELECT = '[STD-PROJECT] SELECT',
}

export default Types;
