import { iStore } from 'Model';
import MemberMiddleware from 'Controller/Member/Member.middleware';
import ProductMiddleware from 'Controller/Product/Product.middleware';
import ProductItemMiddleware from 'Controller/ProductItem/ProductItem.middleware';
import VendorMiddleware from 'Controller/Vendor/Vendor.middleware';
import ServiceMiddleware from 'Controller/Service/Service.middleware';
import NetworkMiddleware from 'Controller/Network/Network.middleware';
import TenantMiddleware from 'Controller/Tenant/Tenant.middleware';
import OnboardingMiddleware from 'Controller/Onboarding/Onboarding.middleware';
import apiClient from 'Api/client';
import { ApolloClient } from 'apollo-client';
import StandardProjectMiddleware from './StandardProject/StandardProject.middleware';

export type iReduxStore = {
  dispatch: (any) => any;
  getState: () => iStore;
};

export type ReduxMiddlewareFunction = (
  store: iReduxStore,
  next: any,
  action: any,
  apiClient: ApolloClient<any>
) => any;

const middlewares = {
  ...MemberMiddleware,
  ...ProductMiddleware,
  ...ProductItemMiddleware,
  ...VendorMiddleware,
  ...ServiceMiddleware,
  ...NetworkMiddleware,
  ...TenantMiddleware,
  ...OnboardingMiddleware,
  ...StandardProjectMiddleware,
};

const Middleware = (store: any) => (next: any) => (action: any) => {
  if (action?.type) {
    // @ts-ignore
    const middleware = middlewares[action.type];
    if (middleware) {
      middleware(store, next, action, apiClient);
    }
  }
};

export default Middleware;
