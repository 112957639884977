import { useState, useEffect } from 'react';
import { Item } from 'Api/types.generated';
import { ButtonWithConfirmation } from 'Components/common/Button';
import { Form, Input, Select, Alert, Popconfirm, Button, Modal } from 'antd';

interface Values {
  category: string;
  configId: string;
  amiId: string;
  amiName: string;
  instanceType: string;
  operatingSystem: string;
  duration: string;
  volumeType: string;
  iops: number;
  size: number;
  throughPut: number;
  loadBalancerType: string;
  deployable: boolean;
}

const remapToValues = (productItem): Values => {
  return {
    category: productItem?.configuration.category,
    configId: productItem?.configurationId || '',
    // compute
    amiId: productItem?.configuration.amiId || '',
    amiName: productItem?.configuration.amiName || '',
    instanceType: productItem?.configuration.instanceType || '',
    operatingSystem: productItem?.configuration.operatingSystem || '',
    // licence
    duration: productItem?.configuration.duration || '',
    // block
    volumeType: productItem?.configuration.volumeType || '',
    iops: productItem?.configuration.iops || 0,
    size: productItem?.configuration.size || 0,
    throughPut: productItem?.configuration.throughPut || 0,
    // network
    loadBalancerType: productItem?.configuration.loadBalancerType || '',
    // general
    deployable: false,
  };
};

const RelationalForm = ({
  productItem,
  handleSubmit,
}: {
  productItem: Item | null;
  handleSubmit: (values: any) => void;
}) => {
  const blockVolumeTypes = ['gp2', 'gp3', 'st1'];

  const operatingSystemOptions = ['windows', 'linux'];

  const [values, setValues] = useState<Values>(remapToValues(productItem));

  useEffect(() => {
    setValues(remapToValues(productItem));
  }, [productItem]);

  return (
    <Form layout="vertical">
      {productItem && (
        <Form.Item label="Config Id">
          <Input disabled={true} type="text" value={values.configId} />
          <small id="dynamoId" className="form-text text-muted">
            Config DB (AWS Dynamo Db)
          </small>
        </Form.Item>
      )}

      {values.category === 'COMPUTE' && (
        <>
          <Form.Item label="AMI Id">
            <Input
              type="text"
              value={values.amiId}
              onChange={(e) =>
                setValues({
                  ...values,
                  amiId: e.target.value,
                })
              }
            />
          </Form.Item>
          <Form.Item label="AMI Name">
            <Input
              type="text"
              value={values.amiName}
              onChange={(e) =>
                setValues({
                  ...values,
                  amiName: e.target.value,
                })
              }
            />
          </Form.Item>
          <Form.Item label="Instance type">
            <Input
              type="text"
              value={values.instanceType}
              onChange={(e) =>
                setValues({
                  ...values,
                  instanceType: e.target.value,
                })
              }
            />
          </Form.Item>
          <Form.Item label="Operating system">
            <Select
              value={values.operatingSystem}
              onChange={(value) => {
                setValues({
                  ...values,
                  operatingSystem: value,
                });
              }}
              options={operatingSystemOptions.map((os) => {
                return { value: os, label: String(os) };
              })}
            />
          </Form.Item>
        </>
      )}

      {values.category === 'LICENSE' && (
        <Form.Item label="Duration">
          <Input
            type="text"
            className="form-control"
            id="duration"
            value={values.duration}
            onChange={(e) =>
              setValues({
                ...values,
                duration: e.target.value,
              })
            }
          />
        </Form.Item>
      )}

      {values.category === 'NETWORK' && (
        <>
          <Form.Item label="Load balancer type">
            <Input
              type="text"
              value={values.loadBalancerType}
              onChange={(e) =>
                setValues({
                  ...values,
                  loadBalancerType: e.target.value,
                })
              }
            />
          </Form.Item>
        </>
      )}

      {values.category === 'GENERAL' && (
        <>
          <Form.Item label="Is deployable?">
            <Select
              value={values.deployable}
              onChange={(value) => {
                setValues({
                  ...values,
                  deployable: Boolean(value),
                });
              }}
              options={[true, false].map((d) => {
                return { value: d, label: String(d) };
              })}
            />
          </Form.Item>
        </>
      )}

      {values.category === 'BLOCK' && (
        <>
          <Alert
            message="Elastic block device provisioning"
            description="Ensure the provisioned cloudformation script has qualified the EBS volume type selected"
            type="info"
            showIcon
            style={{ marginBottom: '1rem' }}
          />
          <Form.Item label="Volume type">
            <Select
              value={values.volumeType}
              onChange={(value) => {
                setValues({
                  ...values,
                  category: value,
                });
              }}
              options={blockVolumeTypes.map((vt) => {
                return { value: vt, label: vt };
              })}
            />
          </Form.Item>
          <Form.Item label="Volume IOPS">
            <Input
              type="number"
              min="0"
              value={values.iops}
              onChange={(e) =>
                setValues({
                  ...values,
                  iops: parseInt(e.target.value, 10),
                })
              }
            />
          </Form.Item>
          <Form.Item label="Volume size">
            <Input
              type="number"
              value={values.size}
              min="0"
              onChange={(e) =>
                setValues({
                  ...values,
                  size: parseInt(e.target.value, 10),
                })
              }
            />
          </Form.Item>
          <Form.Item label="Volume throughput">
            <Input
              type="number"
              min="0"
              value={values.throughPut}
              onChange={(e) =>
                setValues({
                  ...values,
                  throughPut: parseInt(e.target.value, 10),
                })
              }
            />
          </Form.Item>
        </>
      )}

      <div className="custom-form-button-group">
        <Popconfirm
          title={productItem ? 'Update' : 'Create'}
          onConfirm={() => handleSubmit(values)}
          okText="Yes"
          cancelText="No"
        >
          <Button type="primary">{productItem ? 'Update' : 'Create'}</Button>
        </Popconfirm>
      </div>
    </Form>
  );
};

export default RelationalForm;
