import Type from 'Controller/Network/Network.type';
import { ReduxMiddlewareFunction } from 'Controller/middleware';

type NetworkMiddleware = Record<Type, ReduxMiddlewareFunction>;

const middleware: NetworkMiddleware = {
  [Type.ADD_ACTIVITY]: async (store, next, action) => {
    const { network } = store.getState();
    const { id, message, type } = action.payload;
    const activities = [...network.activities, { id, message, type }];
    action.payload = { activities };
    next(action);
  },

  [Type.REMOVE_ACTIVITY]: async (store, next, action) => {
    const { network } = store.getState();
    const { id } = action.payload;
    const activities = network.activities.filter((a) => a.id !== id);
    action.payload = { activities };
    next(action);
  },

  [Type.ADD_ERROR]: async (store, next, action) => {
    const { network } = store.getState();
    const { id, message } = action.payload;
    const errors = [...network.errors, { id, message }];
    action.payload = { errors };
    next(action);
  },

  [Type.REMOVE_ERROR]: async (store, next, action) => {
    const { network } = store.getState();
    const { id } = action.payload;
    const errors = network.errors.filter((e) => e.id !== id);
    action.payload = { errors };
    next(action);
  },
};

export default middleware;
