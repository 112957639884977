import { useState, useEffect } from 'react';
import { Vendor } from 'Api/types.generated';
import { Form, Input, Popconfirm, Button } from 'antd';
import { useStore } from 'react-redux';
import { vendorActions } from 'Controller/actions';
import { useRedux } from 'Hooks';

interface Values {
  id: string;
  name: string;
}

const remapToValues = (vendor: Vendor | null): Values => {
  return {
    id: vendor?.id || '',
    name: vendor?.name || '',
  };
};

const InputForm = ({
  vendor,
  handleSubmit,
  handleDelete,
}: {
  vendor: Vendor | null;
  handleSubmit: (values: any) => void;
  handleDelete?: (id: string) => void;
}) => {
  const [values, setValues] = useState<Values>(remapToValues(vendor));

  useEffect(() => {
    setValues(remapToValues(vendor));
  }, [vendor]);

  return (
    <Form>
      {vendor && (
        <Form.Item label="Id">
          <Input disabled={true} type="text" value={values.id} />
        </Form.Item>
      )}

      <Form.Item label="Name">
        <Input
          type="text"
          value={values.name}
          onChange={(e) =>
            setValues({
              ...values,
              name: e.target.value,
            })
          }
        />
      </Form.Item>

      <div className="custom-form-button-group">
        <Popconfirm
          title={vendor ? 'Update' : 'Create'}
          onConfirm={() => handleSubmit(values)}
          okText="Yes"
          cancelText="No"
        >
          <Button type="primary">{vendor ? 'Update' : 'Create'}</Button>
        </Popconfirm>
        {vendor && handleDelete && (
          <Popconfirm
            title={'Delete'}
            onConfirm={() => handleDelete(values.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary" danger>
              Delete
            </Button>
          </Popconfirm>
        )}
      </div>
    </Form>
  );
};

export const New = () => {
  const { dispatch } = useStore();

  const handleSubmit = (values: any) => {
    dispatch(vendorActions.create({ vendor: values }));
  };

  return <InputForm vendor={null} handleSubmit={handleSubmit} />;
};

export const Detail = () => {
  const { dispatch, actions, store } = useRedux();

  const {
    vendorActions: { update, remove },
  } = actions;

  const {
    vendor: { all, selected },
  } = store;

  const vendor = all.find((s) => s.id === selected);
  console.log('selected vendor', vendor);

  const handleSubmit = (values: any) => {
    dispatch(update({ vendor: values }));
  };

  const handleDelete = (id: string) => {
    dispatch(remove({ id }));
  };

  return (
    <InputForm
      vendor={vendor || null}
      handleSubmit={handleSubmit}
      handleDelete={handleDelete}
    />
  );
};
