const checkCookie = (): string => {
  const { REACT_APP_AUTH_COOKIE } = process.env;

  const [, cookieToken] = document.cookie.split('=');

  const _cookie = REACT_APP_AUTH_COOKIE || cookieToken;

  if (!_cookie) {
    const redirectTo = window.location.hostname;
    const [, domain] = redirectTo.split('.');
    window.location.replace(`https://auth.${domain}.cloud?internal=true`);
  }

  return _cookie;
};

export { checkCookie };
