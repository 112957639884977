import Types from 'Controller/ProductItem/ProductItem.type';

export const listAll = () => {
  return {
    type: Types.LIST_ALL,
    payload: null,
  };
};

export const setAll = ({ productItems }: { productItems: Array<any> }) => {
  return {
    type: Types.SET_ALL,
    payload: { productItems },
  };
};

export const select = ({ id }: { id: string | null }) => {
  return {
    type: Types.SELECT,
    payload: { id },
  };
};

export const update = ({ productItem }: { productItem: any }) => {
  return {
    type: Types.UPDATE,
    payload: { productItem },
  };
};

export const updateConfiguration = ({
  configuration,
}: {
  configuration: any;
}) => {
  return {
    type: Types.UPDATE_CONFIGURATION,
    payload: { configuration },
  };
};

export const create = ({ productItem }: { productItem: any }) => {
  return {
    type: Types.CREATE,
    payload: { productItem },
  };
};

export const remove = ({ id }: { id: string }) => {
  return {
    type: Types.REMOVE,
    payload: { id },
  };
};
