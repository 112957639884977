export * from './sleep';
export * from './credentials';

export const flattenObject = (array: Array<any>) => {
  const flat = (obj, concatenator = '.') =>
    Object.keys(obj).reduce((acc, key) => {
      if (typeof obj[key] !== 'object' || !obj[key]) {
        return {
          ...acc,
          [key]: obj[key],
        };
      }

      const flattenedChild = flat(obj[key], concatenator);

      return {
        ...acc,
        ...Object.keys(flattenedChild).reduce(
          (childAcc, childKey) => ({
            ...childAcc,
            [`${key}${concatenator}${childKey}`]: flattenedChild[childKey],
          }),
          {}
        ),
      };
    }, {});

  return array.map((o) => flat(o));
};

export const getApiDomain = (): {
  domain: string;
  subDomain: string;
  extension: string;
} => {
  if (typeof window !== 'undefined') {
    let url = new URL(String(window.location.href));
    const [subDomain, domain, extension] = url.host.split('.');

    return {
      subDomain,
      domain,
      extension,
    };
  } else {
    return {
      subDomain: '',
      domain: '',
      extension: '',
    };
  }
};

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));
