import { OverlayTrigger, Popover, Button } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

export const ButtonWithConfirmation = ({
  buttonText,
  modalTitle = 'Confirm',
  handleConfirmation,
  style = 'primary',
}: {
  buttonText: string;
  modalTitle?: string;
  handleConfirmation: (any?: any) => any;
  style?: 'primary' | 'secondary' | 'danger' | 'dark';
}) => {
  const popover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">{modalTitle}</Popover.Header>
      <Popover.Body>
        <Button variant="success" onClick={handleConfirmation}>
          <Icon.CheckCircleFill
            className="ConfirmButton__icon"
            color={'white'}
          />
          Confirm
        </Button>
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger trigger="click" placement="top" overlay={popover} rootClose>
      <Button variant={`outline-${style}`}>{buttonText}</Button>
    </OverlayTrigger>
  );
};
