import { Product } from 'Api/types.generated';
import Type from './Product.type';

export interface ProductState {
  all: Array<Product>;
  categorizeById: {
    teradici: Array<string>;
    workstation: Array<string>;
    editshare: Array<string>;
    transfer: Array<string>;
    activeDirectory: Array<string>;
  };
  selected: string | null;
}

const initalState: ProductState = {
  all: [],
  categorizeById: {
    teradici: [],
    workstation: [],
    transfer: [],
    editshare: [],
    activeDirectory: [],
  },
  selected: null,
};

type Action = {
  type: Type;
  payload: {
    id?: string | null;
    products?: Array<Product>;
    categorizeById?: {
      teradici: Array<string>;
      workstation: Array<string>;
      transfer: Array<string>;
      editshare: Array<string>;
      activeDirectory: Array<string>;
      userSelected: Product;
    };
  };
};

export default function ProductReducer(
  state: ProductState = initalState,
  action: Action
): ProductState {
  const { type = '', payload } = action;

  switch (type) {
    case Type.SET_ALL: {
      const { products } = payload;
      return { ...state, all: products as Product[] };
    }

    case Type.SELECT: {
      const { id } = payload;
      return { ...state, selected: id as string };
    }

    default:
      return state;
  }
}
