import { useRedux } from 'Hooks';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';

interface DataType {
  key: React.Key;
  name: string;
  description: string;
  serviceName: string;
  vendorName: string;
}

export const TenantMemberRoleTable = () => {
  const { store } = useRedux();

  const {
    member: { all, selected },
  } = store;

  const member = all.find((m) => m.id === selected);

  return (
    <div className="MemberRole">
      <div className="MemberRole__Table">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Id</th>
              <th scope="col">Role</th>
            </tr>
          </thead>
          <tbody>
            {member?.tenantMemberRoles?.map((tmr) => {
              return (
                <tr key={tmr?.tenant?.id}>
                  <td>{tmr?.tenant?.name}</td>
                  <td>{tmr?.tenant?.awsAccountId}</td>
                  <td>{tmr?.role.name}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export const ProjectMemberRoleTable = () => {
  const { store } = useRedux();

  const {
    member: { all, selected },
  } = store;

  const member = all.find((m) => m.id === selected);

  return (
    <div className="MemberRole">
      <div className="MemberRole__Table">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Slug</th>
              <th scope="col">Role</th>
            </tr>
          </thead>
          <tbody>
            {member?.projectMemberRoles?.map((tmr) => {
              return (
                <tr key={tmr?.project?.id}>
                  <td>{tmr?.project?.name}</td>
                  <td>{tmr?.project?.shortName}</td>
                  <td>{tmr?.role.name}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
