import { notification, Spin } from 'antd';
import { useRedux } from 'Hooks';
import { useEffect, useState, Fragment } from 'react';
import { ClockCircleOutlined, CheckCircleFilled } from '@ant-design/icons';

const NetworkContainer = (props) => {
  const [inProgress, setInProgress] = useState(0);

  const { dispatch, actions, store } = useRedux();

  const {
    network: { activities, errors },
  } = store;

  const {
    networkActions: { removeError },
  } = actions;

  const visiblityClassMod = activities.length > 0 ? '--visible' : '';

  type NotificationType = 'success' | 'info' | 'warning' | 'error';

  const openNotificationWithIcon = ({
    type,
    message,
    icon = null,
  }: {
    type: NotificationType;
    message: string;
    icon?: any;
  }) => {
    notification[type]({
      message,
      icon,
    });
  };

  useEffect(() => {
    const info = activities.filter((a) => {
      return a.type === 'info';
    });

    setInProgress(info.length);

    const success = activities
      .filter((a) => {
        return a.type === 'success';
      })
      .map((a) => {
        openNotificationWithIcon({
          type: a.type,
          message: a.message,
          icon: <CheckCircleFilled style={{ color: '#52c41a' }} />,
        });
      });

    if (errors.length > 0) {
      errors.forEach((err) =>
        openNotificationWithIcon({
          type: 'error',
          message: err.message,
        })
      );
    }
  }, [activities, errors]);

  return (
    <div className={`Network ${visiblityClassMod}`}>
      <Fragment>
        {inProgress > 0 && (
          <Spin tip={`Loading ${inProgress} items`} size="large" />
        )}
        {props.children}
      </Fragment>
    </div>
  );
};

export default NetworkContainer;
