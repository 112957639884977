import Types from './StandardProject.type';
import {
  StandardProjectListItem_DTO,
  StandardProjectRequestStatus,
} from './StandardProject.type';

export const listAll = () => {
  console.log('listAll action in standard project');
  return {
    type: Types.LIST_ALL,
    payload: {},
  };
};

export const setAll = ({
  projects,
}: {
  projects: Array<StandardProjectListItem_DTO>;
}) => {
  return {
    type: Types.SET_ALL,
    payload: { projects },
  };
};

export const setFilter = ({
  statusFilter,
}: {
  statusFilter: StandardProjectRequestStatus;
}) => {
  return {
    type: Types.SET_FILTER,
    payload: { statusFilter },
  };
};

export const select = ({ id }: { id: string | null }) => {
  return {
    type: Types.SELECT,
    payload: { id },
  };
};
