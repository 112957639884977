import Type, { StackDeployment } from 'Controller/Tenant/Tenant.type';
import { ReduxMiddlewareFunction } from 'Controller/middleware';
import { tenantActions } from 'Controller/actions';
import {
  listTenants,
  getAwsBillingCost,
  createTenantWithExistingOwner,
  createTenantWithNewMember,
  deployTenantActiveDirectory,
  deployTenantCertificateAuthority,
  deployTenantCertificateStack,
  deployDirectoryInfrastructure,
  createActiveDirectoryAdmins,
  listTenantStacks,
} from 'Api';
import apiCallWrapper from 'Api/apiCallWrapper';

type MemberMiddleware = Record<Type, ReduxMiddlewareFunction>;

const middleware: MemberMiddleware = {
  [Type.PAGE_ROUTE]: async (store, next, action, apiClient) => {
    const { dispatch } = store;
  },

  [Type.DEPLOY_STACK]: async (store, next, action, apiClient) => {
    try {
      const { dispatch } = store;
      const { deployment } = action.payload;
      const {
        tenant: { selected: tenantId },
      } = store.getState();

      const deploymentMutation = {
        [StackDeployment.ACM_CERTIFICATE]: deployTenantCertificateStack,

        [StackDeployment.AD_CERTIFICATE_AUTHORITY]:
          deployTenantCertificateAuthority,

        [StackDeployment.AD_FSX]: deployTenantActiveDirectory,

        [StackDeployment.AD_ADMINS]: createActiveDirectoryAdmins,

        [StackDeployment.DIRECTORY_INFRASTRUCTURE]:
          deployDirectoryInfrastructure,
      }[deployment as StackDeployment];

      await apiCallWrapper({
        dispatch,
        uiMessage: `Deploying ${deployment} to tenant...`,
        callback: async () => {
          const response = await apiClient.mutate({
            mutation: deploymentMutation,
            variables: { tenantId },
          });
          dispatch(tenantActions.listStacks());
        },
      });
    } catch (e) {
      console.error(e);
    } finally {
      next(action);
    }
  },

  [Type.LIST_ALL]: async (store, next, action, apiClient) => {
    const { dispatch } = store;

    await apiCallWrapper({
      dispatch,
      uiMessage: 'Tenants ...',
      callback: async () => {
        const response = await apiClient.query({ query: listTenants });
        dispatch(tenantActions.setAll({ tenants: response.data.listTenants }));
      },
    });

    next(action);
  },

  [Type.LIST_STACKS]: async (store, next, action, apiClient) => {
    try {
      const { dispatch } = store;

      const {
        tenant: { selected: tenantId },
      } = store.getState();

      const res = await apiCallWrapper({
        dispatch,
        uiMessage: `Listing stacks in tenant account...`,
        callback: async () => {
          const res = await apiClient.mutate({
            mutation: listTenantStacks,
            variables: { tenantId },
          });
          return res;
        },
      });

      console.log({ res });
      const stacks = JSON.parse(res?.data.listTenantStacks.stacks || '[]');
      console.log({ stacks });

      action.payload = {
        ...action.payload,
        stacks,
      };
    } catch (e) {
      console.error(e);
    } finally {
      next(action);
    }
  },

  [Type.SET_AWS_COSTS]: async (store, next, action) => {
    next(action);
  },

  [Type.GET_AWS_COSTS]: async (store, next, action, apiClient) => {
    const { dispatch } = store;

    const { tenantId, start, end } = action.payload;

    await apiCallWrapper({
      dispatch,
      uiMessage: 'AWS Costs ...',
      callback: async () => {
        const r = await apiClient.query({
          query: getAwsBillingCost,
          variables: { tenantId, start, end },
        });
        dispatch(
          tenantActions.setAwsCosts({ awsCosts: r.data.getAwsBillingCost })
        );
      },
    });

    next(action);
  },

  [Type.SET_ALL]: async (store, next, action) => {
    next(action);
  },

  [Type.SELECT]: async (store, next, action) => {
    try {
      const {
        tenant: { all: allTenants },
      } = store.getState();

      const selectedTenant = allTenants.find((t) => t.id === action.payload.id);

      if (!selectedTenant) return;

      action.payload = {
        ...action.payload,
      };
    } catch (e) {
      console.error(e);
    } finally {
      next(action);
    }
  },

  [Type.CREATE_NEW]: async (store, next, action, apiClient) => {
    const { dispatch } = store;

    const {
      tenantName,
      tenantShortName,
      phoneNumber,
      givenName,
      familyName,
      email,
      ownerId,
      addressLine1,
      addressLine2,
      city,
      country,
      isDryRun,
      postcode,
    } = action.payload;

    await apiCallWrapper({
      dispatch,
      uiMessage: 'Creating new tenant ...',
      callback: async () => {
        const response = ownerId
          ? await apiClient.mutate({
              mutation: createTenantWithExistingOwner,
              variables: {
                ownerId,
                tenantName,
                tenantShortName,
                addressLine1,
                addressLine2,
                city,
                country,
                postcode,
                isDryRun,
              },
            })
          : await apiClient.mutate({
              mutation: createTenantWithNewMember,
              variables: {
                phoneNumber,
                givenName,
                familyName,
                email,
                tenantName,
                tenantShortName,
                addressLine1,
                addressLine2,
                city,
                country,
                postcode,
                isDryRun,
              },
            });

        dispatch(tenantActions.setAll({ tenants: response.data.listTenants }));
      },
    });
    next(action);
  },
};

export default middleware;
