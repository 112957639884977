import Type from 'Controller/Service/Service.type';
import { ReduxMiddlewareFunction } from 'Controller/middleware';
import { serviceActions } from 'Controller/actions';
import { listServices, createService, upsertService, deleteService } from 'Api';
import apiCallWrapper from 'Api/apiCallWrapper';

type MemberMiddleware = Record<Type, ReduxMiddlewareFunction>;

const { setAll, listAll, select } = serviceActions;

const middleware: MemberMiddleware = {
  [Type.LIST_ALL]: async (store, next, action, apiClient) => {
    const { dispatch } = store;

    await apiCallWrapper({
      dispatch,
      uiMessage: 'Services ...',
      callback: async () => {
        const response = await apiClient.query({ query: listServices });
        dispatch(setAll({ services: response.data.listServices }));
      },
    });

    next(action);
  },

  [Type.UPDATE]: async (store, next, action, apiClient) => {
    const { dispatch } = store;

    const {
      service: { id, description, name },
    } = action.payload;

    await apiCallWrapper({
      dispatch,
      uiMessage: `Updating service ${name} ...`,
      callback: async () => {
        const response = await apiClient.mutate({
          mutation: upsertService,
          variables: {
            id,
            description,
            name,
          },
        });
        dispatch(listAll());
        dispatch(select({ id: response.data.upsertService.id }));
      },
    });

    next(action);
  },

  [Type.CREATE]: async (store, next, action, apiClient) => {
    const { dispatch } = store;

    const {
      service: { description, name },
    } = action.payload;

    await apiCallWrapper({
      dispatch,
      uiMessage: `Creating service ${name} ...`,
      callback: async () => {
        const response = await apiClient.mutate({
          mutation: createService,
          variables: {
            description,
            name,
          },
        });
        dispatch(listAll());
        dispatch(select({ id: response.data.createService.id }));
      },
    });

    next(action);
  },

  [Type.REMOVE]: async (store, next, action, apiClient) => {
    const { dispatch } = store;

    const { id } = action.payload;

    await apiCallWrapper({
      dispatch,
      uiMessage: `Deleting service ...`,
      callback: async () => {
        await apiClient.mutate({
          mutation: deleteService,
          variables: {
            serviceId: id,
          },
        });
        dispatch(listAll());
        dispatch(select({ id: null }));
      },
    });

    next(action);
  },

  [Type.SET_ALL]: async (store, next, action, apiClient) => {
    next(action);
  },

  [Type.SELECT]: async (store, next, action, apiClient) => {
    next(action);
  },
};

export default middleware;
