import { useState, useEffect } from 'react';
import { Item } from 'Api/types.generated';
import { useMetaFieldValidation } from 'Hooks';
import { Form, Input, Select, Popconfirm, Button } from 'antd';

interface Values {
  id: string;
  name: string;
  description: string;
  meta: string;
  category: string;
  configId: string;
  createdAt: string;
  updatedAt: string;
  amiId: string;
  instanceType: string;
  operatingSystem: string;
  duration: string;
  volumeType: string;
  iops: string;
  size: string;
  throughPut: string;
  deployable: boolean;
}

const remapToValues = (productItem: Item | null, itemCategories): Values => {
  return {
    id: productItem?.id || '',
    name: productItem?.name || '',
    description: productItem?.description || '',
    meta: productItem?.meta || '{}',
    category: productItem?.category || itemCategories[0],
    configId: productItem?.configurationId || '',
    createdAt: productItem?.configuration?.createdAt || '',
    updatedAt: productItem?.configuration?.updatedAt || String(new Date()),
    // compute
    amiId: '',
    instanceType: '',
    operatingSystem: '',
    // licence
    duration: '',
    // block
    volumeType: '',
    iops: '',
    size: '',
    throughPut: '',
    // general
    deployable: false,
  };
};

const AttributeForm = ({
  productItem,
  handleSubmit,
  handleDelete,
}: {
  productItem: Item | null;
  handleSubmit: (values: any) => void;
  handleDelete?: (id: string) => void;
}) => {
  const itemCategories = [
    'COMPUTE',
    'LICENSE',
    'BLOCK',
    'OBJECT',
    'NETWORK',
    'GENERAL',
  ];

  const [values, setValues] = useState<Values>(
    remapToValues(productItem, itemCategories)
  );

  useEffect(() => {
    setValues(remapToValues(productItem, itemCategories));
  }, [productItem]);

  const [metaFieldError] = useMetaFieldValidation(values.meta);

  return (
    <Form layout="vertical">
      {productItem && (
        <>
          <Form.Item label="Id">
            <Input disabled={true} type="text" value={values.id} />
          </Form.Item>
          <Form.Item label="Created at">
            <Input
              disabled={true}
              type="text"
              value={new Date(values.createdAt).toLocaleString()}
            />
          </Form.Item>
          <Form.Item label="Updated at">
            <Input
              disabled={true}
              type="text"
              value={new Date(values.updatedAt).toLocaleString()}
            />
          </Form.Item>
        </>
      )}

      <Form.Item label="Name">
        <Input
          type="text"
          value={values.name}
          onChange={(e) => {
            setValues({
              ...values,
              name: e.target.value,
            });
          }}
        />
      </Form.Item>

      <Form.Item label="Description">
        <Input
          type="text"
          value={values.description}
          onChange={(e) =>
            setValues({
              ...values,
              description: e.target.value,
            })
          }
        />
      </Form.Item>

      <Form.Item
        label="Metadata"
        help={metaFieldError}
        validateStatus={metaFieldError ? 'error' : 'success'}
      >
        <Input
          type="text"
          value={values.meta}
          onChange={(e) =>
            setValues({
              ...values,
              meta: e.target.value,
            })
          }
        />
      </Form.Item>

      <Form.Item label="Category">
        <Select
          value={values.category}
          onChange={(value: string) => {
            setValues({
              ...values,
              category: value,
            });
          }}
          options={itemCategories.map((c) => {
            return { value: c, label: c };
          })}
        />
      </Form.Item>

      <div className="custom-form-button-group">
        <Popconfirm
          title={productItem ? 'Update' : 'Create'}
          onConfirm={() => handleSubmit(values)}
          okText="Yes"
          cancelText="No"
        >
          <Button type="primary">{productItem ? 'Update' : 'Create'}</Button>
        </Popconfirm>
        {productItem && handleDelete && (
          <Popconfirm
            title={'Delete'}
            onConfirm={() => handleDelete(values.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary" danger>
              Delete
            </Button>
          </Popconfirm>
        )}
      </div>
    </Form>
  );
};

export default AttributeForm;
