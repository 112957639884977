import { Fragment, useState, useEffect } from 'react';
import { Table } from 'antd';
import { useRedux, useFilter } from 'Hooks';
import { Tenant } from 'Api/types.generated';
import type { ColumnsType } from 'antd/es/table';

interface DataType {
  key: React.Key;
  id: string;
  name: string;
  awsAccountId: string;
}

const List = ({ handleSelection }) => {
  const [columns, setColumns] = useState<ColumnsType<DataType>>([]);

  const { store } = useRedux();

  const {
    tenant: { all },
  } = store;

  const { filterResults, FilterForm } = useFilter<Tenant>(all);

  useEffect(() => {
    setColumns([
      { title: 'Name', dataIndex: 'name', key: 'name' },
      { title: 'Id', dataIndex: 'id', key: 'id' },
      { title: 'AWS Account', dataIndex: 'awsAccountId', key: 'awsAccountId' },
    ]);
  }, []);

  return (
    <Fragment>
      <FilterForm />
      <Table
        rowSelection={{
          type: 'radio',
          onChange: (e) => handleSelection(e),
        }}
        dataSource={filterResults.map((tenant) => {
          const { id, name, awsAccountId } = tenant;

          return {
            key: id,
            name,
            id,
            awsAccountId,
          };
        })}
        columns={columns}
      />
    </Fragment>
  );
};

export default List;
