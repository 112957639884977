import List from 'Components/Product/List';
import Detail from 'Components/Product/Detail';
import Items from 'Components/Product/Items';
import { useRedux } from 'Hooks';
import { Space, Card, Typography } from 'antd';
import React, { useState } from 'react';
import _get from 'lodash.get';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

const Container = () => {
  const [showSearch, setShowSearch] = useState(true);
  const [showConfiguration, setShowConfiguration] = useState(true);
  const [showComponents, setShowComponents] = useState(true);

  const { dispatch, actions, store } = useRedux();

  const {
    productActions: { select },
  } = actions;

  const {
    product: { all, selected },
  } = store;

  const handleTableSelection = (selectedRowKeys: React.Key[]) => {
    const _selection = all.find((p) => p.id === String(selectedRowKeys));
    _selection && dispatch(select({ id: _selection.id }));
    document.getElementById('product-configuration-form')?.scrollIntoView();
    setShowConfiguration(true);
  };

  return (
    <Space
      style={{
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
      }}
      size="large"
    >
      <Typography.Title level={2} style={{ width: 762, textAlign: 'left' }}>
        Product
      </Typography.Title>
      <Card
        title="Search"
        style={{ width: 762 }}
        extra={
          showSearch ? (
            <DownOutlined onClick={() => setShowSearch(false)} />
          ) : (
            <UpOutlined onClick={() => setShowSearch(true)} />
          )
        }
        bodyStyle={{ display: showSearch ? 'block' : 'none' }}
      >
        <List handleSelection={handleTableSelection} />
      </Card>
      <Card
        title="Configuration"
        style={{ width: 762 }}
        id={'product-configuration-form'}
        extra={
          showConfiguration ? (
            <DownOutlined onClick={() => setShowConfiguration(false)} />
          ) : (
            <UpOutlined onClick={() => setShowConfiguration(true)} />
          )
        }
        bodyStyle={{ display: showConfiguration ? 'block' : 'none' }}
      >
        <Detail />
      </Card>
      {selected && (
        <Card
          title="Components"
          style={{ width: 762 }}
          extra={
            showComponents ? (
              <DownOutlined onClick={() => setShowComponents(false)} />
            ) : (
              <UpOutlined onClick={() => setShowComponents(true)} />
            )
          }
          bodyStyle={{ display: showComponents ? 'block' : 'none' }}
        >
          <Items />
        </Card>
      )}
    </Space>
  );
};

export default Container;
