import Types from './Network.type';

export const addActivity = ({
  id,
  message,
  type,
}: {
  id: string;
  message: string;
  type: 'success' | 'info' | 'warning' | 'error';
}) => {
  return {
    type: Types.ADD_ACTIVITY,
    payload: { id, message, type },
  };
};

export const removeActivity = ({ id }: { id: string }) => {
  return {
    type: Types.REMOVE_ACTIVITY,
    payload: { id },
  };
};

export const addError = ({ id, message }: { id: string; message: string }) => {
  return {
    type: Types.ADD_ERROR,
    payload: { id, message },
  };
};

export const removeError = ({ id }: { id: string }) => {
  return {
    type: Types.REMOVE_ERROR,
    payload: { id },
  };
};
