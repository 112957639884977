import React from 'react';
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  PlusCircleTwoTone,
  ClockCircleTwoTone,
} from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { useRedux } from 'Hooks';
import { Button, Form, Select, Divider, List } from 'antd';
import ActiveDirectoryDeploymentTimeline from './timeline';
import { PageContainer, CollapsableCard } from '../../common';

const { Item } = Form;
const { Option } = Select;

const TenantDeployStacks = () => {
  const [cfnFilter, setCfnFilter] = useState('');

  const [cfnFilterOptions, setCfnFilterOptions] = useState<string[]>([]);

  const {
    dispatch,
    actions: {
      tenantActions: { select: selectTenant, listStacks },
    },
    store: {
      tenant: {
        selected: selectedTenant,
        all: allTenants,
        selectedTenantStacks,
      },
    },
  } = useRedux();

  useEffect(() => {
    if (selectedTenantStacks.length > 0) {
      const stackstatus = selectedTenantStacks.map((s) => s.StackStatus);
      const _cfnFilterOptions = Array.from(new Set(stackstatus)).filter(
        (s) => !!s
      ) as string[];
      setCfnFilterOptions(_cfnFilterOptions);
    }
  }, [selectedTenantStacks]);

  return (
    <PageContainer title="Deploy stacks to tenant">
      <React.Fragment>
        <CollapsableCard title="Select tenant">
          <Form
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 14 }}
            layout="horizontal"
          >
            <Item label="Tenant">
              <Select
                allowClear
                defaultValue={null}
                value={selectedTenant}
                onChange={(id) => dispatch(selectTenant({ id }))}
              >
                {allTenants.map((t) => (
                  <Option
                    key={t.id}
                    value={t.id}
                  >{`${t.name} - ${t.awsAccountId}`}</Option>
                ))}
              </Select>
            </Item>
          </Form>
        </CollapsableCard>
        <CollapsableCard title="Cloudformation stacks">
          <Form
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 14 }}
            layout="horizontal"
          >
            {' '}
            {selectedTenant && (
              <>
                <Item label="Stack list">
                  <Button onClick={() => dispatch(listStacks())}>
                    Refresh
                  </Button>
                </Item>
                {cfnFilterOptions.length > 0 && (
                  <Item label="Filter">
                    <Select
                      allowClear
                      defaultValue={null}
                      value={cfnFilter}
                      onChange={(o) => setCfnFilter(o)}
                    >
                      {cfnFilterOptions.map((o) => (
                        <Option key={o} value={o}>{`${o}`}</Option>
                      ))}
                    </Select>
                  </Item>
                )}
                <List
                  itemLayout="horizontal"
                  dataSource={selectedTenantStacks.filter(
                    (s) => s.StackStatus === cfnFilter
                  )}
                  style={{ paddingLeft: '16.6%' }}
                  renderItem={(item) => {
                    const icon = {
                      CREATE_COMPLETE: (
                        <CheckCircleTwoTone
                          twoToneColor="#50C878"
                          style={{ fontSize: '1rem' }}
                        />
                      ),
                      UPDATE_COMPLETE: (
                        <PlusCircleTwoTone
                          twoToneColor="#00BFFF"
                          style={{ fontSize: '1rem' }}
                        />
                      ),
                      DELETE_COMPLETE: (
                        <CloseCircleTwoTone
                          twoToneColor="#FF4500"
                          style={{ fontSize: '1rem' }}
                        />
                      ),
                      CREATE_IN_PROGRESS: (
                        <ClockCircleTwoTone
                          twoToneColor="#F8DE7E"
                          style={{ fontSize: '1rem' }}
                        />
                      ),
                    }[item.StackStatus as string];

                    return (
                      <List.Item>
                        <List.Item.Meta
                          avatar={icon}
                          title={<strong>{item.StackName}</strong>}
                          description={item.StackStatus}
                        />
                      </List.Item>
                    );
                  }}
                ></List>
              </>
            )}
          </Form>
        </CollapsableCard>
        <CollapsableCard title="Active Directory Deployment">
          <Form
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 14 }}
            layout="horizontal"
          >
            {selectedTenantStacks.length > 0 && (
              <ActiveDirectoryDeploymentTimeline />
            )}
          </Form>
        </CollapsableCard>
      </React.Fragment>
    </PageContainer>
  );
};

export { TenantDeployStacks };
