import { useRedux } from 'Hooks';

const ServiceProduct = () => {
  const { dispatch, store, actions } = useRedux();

  const {
    service: { all, selected },
  } = store;

  const { productActions } = actions;

  const service = all.find((v) => v.id === selected);

  return (
    <table className="table">
      <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Vendor</th>
        </tr>
      </thead>
      <tbody>
        {service?.products?.map((p) => {
          return (
            <tr
              key={p?.id}
              onClick={() => {
                dispatch(productActions.select({ id: p?.id || null }));
              }}
            >
              <td>{p?.name}</td>
              <td>{p?.vendor.name}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default ServiceProduct;
