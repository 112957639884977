import { Table } from 'antd';
import { useRedux } from 'Hooks';
import { Product } from 'Api/types.generated';
import React, { useEffect, useState } from 'react';
import type { ColumnsType } from 'antd/es/table';
import _get from 'lodash.get';

interface DataType {
  key: React.Key;
  name: string;
  description: string;
  serviceName: string;
  vendorName: string;
}

const VendorProductItem = () => {
  const [dataSource, setDataSource] = useState<DataType[]>([]);
  const [columns, setColumns] = useState<ColumnsType<DataType>>([]);

  const { store } = useRedux();

  const {
    vendor: { all, selected },
  } = store;

  const vendor = all.find((v) => v.id === selected);

  useEffect(() => {
    if (vendor) {
      const products = _get(vendor, 'products', []) as Product[];

      console.log('products', products);

      const _dataSource = products.map((p) => {
        const {
          id,
          name,
          description,
          service: { name: serviceName } = { name: '' },
          vendor: { name: vendorName } = { name: '' },
        } = p;

        return {
          key: id,
          name,
          description,
          serviceName,
          vendorName,
        };
      });

      setDataSource(_dataSource);

      setTimeout(() => {
        setColumns([
          { title: 'Name', dataIndex: 'name', key: 'name' },
          {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
          },
          {
            title: 'Service',
            dataIndex: 'serviceName',
            key: 'serviceName',
          },
          {
            title: 'Vendor',
            dataIndex: 'vendorName',
            key: 'vendorName',
          },
        ]);
      }, 500);
    }
  }, [all]);

  console.log('dataSource', dataSource);
  console.log('columns', columns);

  return <Table dataSource={dataSource} columns={columns} />;
};

export default VendorProductItem;
