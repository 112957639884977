import Type from 'Controller/Onboarding/Onboarding.type';
import { ReduxMiddlewareFunction } from 'Controller/middleware';
import apiCallWrapper from 'Api/apiCallWrapper';
import { list, setSendStatus } from './Onboarding.action';
import {
  listInvitations,
  sendInvitations,
  resendInvitation,
  revokeInvitation,
} from 'Api';

type OnboardingMiddleware = Record<Type, ReduxMiddlewareFunction>;

const middleware: OnboardingMiddleware = {
  [Type.SET_SEND_STATUS]: async (store, next, action, apiClient) => {
    next(action);
  },

  [Type.INVITE]: async (store, next, action, apiClient) => {
    const { dispatch } = store;
    const { projectId, invitations } = action.payload;

    const {
      tenant: { selected, all },
    } = store.getState();

    if (!selected) {
      throw Error(`No tenant selected ID = ${selected}`);
    }

    const tenant = all.find((t) => t.id === selected);

    const project = tenant?.projects?.find((p) => p?.id === projectId);

    if (!project) {
      throw Error(`Could not find project with ID = ${projectId}`);
    }

    try {
      await apiCallWrapper({
        dispatch: dispatch,
        uiMessage: `Inviting users to ${project.name}...`,
        callback: async () => {
          await apiClient.mutate({
            mutation: sendInvitations,
            variables: { projectId, invitations },
          });

          dispatch(list({ projectId }));
          dispatch(setSendStatus({ sendStatus: true }));
        },
      });
    } catch (e) {
      console.error(e);
      dispatch(setSendStatus({ sendStatus: false }));
    } finally {
      next(action);
    }
  },

  [Type.LIST]: async (store, next, action, apiClient) => {
    const { dispatch } = store;
    const { projectId } = action.payload;

    try {
      await apiCallWrapper({
        dispatch: dispatch,
        uiMessage: `List all invitiations...`,
        callback: async () => {
          const response = await apiClient.query({
            query: listInvitations,
            variables: { projectId },
          });

          action.payload.invitations = response.data.listInvitations;
        },
      });
    } catch (e) {
      console.error(e);
    } finally {
      next(action);
    }
  },

  [Type.RESEND]: async (store, next, action, apiClient) => {
    const { dispatch } = store;
    const { inviteId, projectId } = action.payload;

    try {
      await apiCallWrapper({
        dispatch: dispatch,
        uiMessage: `Resending invitation...`,
        callback: async () => {
          await apiClient.mutate({
            mutation: resendInvitation,
            variables: { inviteId, projectId },
          });

          dispatch(list({ projectId }));
        },
      });
    } catch (e) {
      console.error(e);
    } finally {
      next(action);
    }
  },

  [Type.REVOKE]: async (store, next, action, apiClient) => {
    const { dispatch } = store;
    const { inviteId, projectId } = action.payload;

    try {
      await apiCallWrapper({
        dispatch: dispatch,
        uiMessage: `Revoking invitation...`,
        callback: async () => {
          const response = await apiClient.mutate({
            mutation: revokeInvitation,
            variables: { inviteId, projectId },
          });

          dispatch(list({ projectId }));
        },
      });
    } catch (e) {
      console.error(e);
    } finally {
      next(action);
    }
  },
};

export default middleware;
