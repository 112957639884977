import Type, { StandardProjectRequestStatus } from './StandardProject.type';
import { StandardProjectListItem_DTO } from './StandardProject.type';

export interface StandardProjectState {
  all: Array<StandardProjectListItem_DTO>;

  selectedId: number | null;
  selectedProject: StandardProjectListItem_DTO | null;
}

const initalState: StandardProjectState = {
  all: [],

  selectedId: null,
  selectedProject: null,
};

type Action = {
  type: Type;
  payload: {
    projects: Array<StandardProjectListItem_DTO>;

    id: number | null;
    selectedProject: StandardProjectListItem_DTO | null;
  };
};

export default function StandardProjectReducer(
  state: StandardProjectState = initalState,
  action: Action
): StandardProjectState {
  const { type = '', payload } = action;

  switch (type) {
    case Type.SET_ALL: {
      const { projects } = payload;
      return { ...state, all: projects };
    }

    case Type.SELECT: {
      const { id, selectedProject } = payload;
      return {
        ...state,
        selectedId: id,
        selectedProject,
      };
    }

    default:
      return state;
  }
}
