import { Vendor } from 'Api/types.generated';
import Type from 'Controller/Vendor/Vendor.type';

export interface VendorState {
  all: Array<Vendor>;
  selected: string | null;
}

const initalState: VendorState = {
  all: [],
  selected: null,
};

type Action = {
  type: Type;
  payload: {
    vendors: Array<Vendor>;
    id: string | null;
  };
};

export default function VendorReducer(
  state: VendorState = initalState,
  action: Action
): VendorState {
  const { type = '', payload } = action;

  switch (type) {
    case Type.SET_ALL: {
      const { vendors } = payload;
      return { ...state, all: vendors };
    }

    case Type.SELECT: {
      const { id } = payload;
      return { ...state, selected: id };
    }

    default:
      return state;
  }
}
