import { useEffect, useState } from 'react';
import { useRedux } from 'Hooks';
import { Divider, Table, Button, Tag, Tooltip } from 'antd';
import { StopOutlined, SendOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';
import { InvitationStatus } from 'Api/types.generated';

interface TableData {
  id: string;
  email: string;
  role: string;
  status: string;
  expires: string;
  errorReason?: string;
}

export const ROLE_NAME_BY_ID = {
  '2': 'Admin',
  '3': 'Ops',
  '4': 'User',
};

const InvitesTable = ({ selectedProject }: { selectedProject: number }) => {
  const [tableData, setTableData] = useState<Array<TableData>>();

  const {
    dispatch,
    actions: {
      onboardingActions: { list, resend, revoke },
    },
    store: {
      onboarding: { invitations },
    },
  } = useRedux();

  const getTagColor = (status: string) => {
    enum Colors {
      SENT = 'blue',
      ATTEMPTED = 'orange',
      ACCEPTED = 'green',
      REVOKED = 'red',
      FAILED = 'red',
      RESENT = 'blue',
    }

    return Colors[status];
  };

  const columns: ColumnsType<TableData> = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_, { status, errorReason }) => {
        const tag = (
          <Tag color={getTagColor(status)} key={status}>
            {status.toUpperCase()}
          </Tag>
        );

        return errorReason ? (
          <Tooltip placement="top" title={errorReason}>
            {tag}
          </Tooltip>
        ) : (
          tag
        );
      },
    },
    {
      title: 'Expires',
      dataIndex: 'expires',
      key: 'expires',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, { id: inviteId, status }) => {
        if (!selectedProject || status === InvitationStatus.Accepted) {
          return null;
        }

        return (
          <>
            <Tooltip placement="rightTop" title={'Resend'}>
              <Button
                icon={<SendOutlined />}
                type="text"
                color="primary"
                onClick={() =>
                  dispatch(resend({ inviteId, projectId: selectedProject }))
                }
              />
            </Tooltip>
            <Tooltip placement="rightTop" title={'Revoke'} color={'red'}>
              <Button
                icon={<StopOutlined />}
                type="text"
                danger
                onClick={() =>
                  dispatch(revoke({ inviteId, projectId: selectedProject }))
                }
              />
            </Tooltip>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (selectedProject) {
      dispatch(list({ projectId: selectedProject }));
    }
  }, [selectedProject]);

  useEffect(() => {
    setTableData(() => {
      return invitations.map((inv) => {
        return {
          id: inv.id,
          email: inv.email,
          role: ROLE_NAME_BY_ID[String(inv.roleId)],
          status: inv.status,
          expires: new Date(inv.expires).toLocaleString(),
          errorReason: inv.errorReason || '',
        };
      });
    });
  }, [invitations]);

  return (
    <div className="InvitesTable">
      <Divider orientation="left" style={{ margin: '2rem 0' }}>
        Sent
      </Divider>
      <div className="invitation-container">
        <Button
          style={{ margin: '1rem' }}
          type="primary"
          onClick={() => dispatch(list({ projectId: selectedProject }))}
        >
          Refresh
        </Button>
        <Table columns={columns} dataSource={tableData} />
      </div>
    </div>
  );
};

export default InvitesTable;
