enum Types {
  SET_ALL = '[SERVICE] SET_ALL',
  LIST_ALL = '[SERVICE] LIST_ALL',
  SELECT = '[SERVICE] SELECT',
  UPDATE = '[SERVICE] UPDATE',
  CREATE = '[SERVICE] CREATE',
  REMOVE = '[SERVICE] REMOVE',
}

export default Types;
