import { useEffect, useState } from 'react';
import { flattenObject } from 'Utils';
import _get from 'lodash.get';
import { Form, Select, AutoComplete, Input } from 'antd';

type SelectOption = { value: string; label: string };

type AutocompleteOption = { value: string };

interface Response<T> {
  filterOptions: Array<SelectOption>;
  setFilterOptions: React.Dispatch<React.SetStateAction<SelectOption[]>>;
  filterKey: string;
  setFilterKey: React.Dispatch<React.SetStateAction<string>>;
  filterValue: string;
  setFilterValue: React.Dispatch<React.SetStateAction<string>>;
  filterValueOptions: Array<AutocompleteOption>;
  setFilterValueOptions: React.Dispatch<
    React.SetStateAction<Array<AutocompleteOption>>
  >;
  filterResults: Array<T>;
  setFilterResults: React.Dispatch<React.SetStateAction<Array<T>>>;
  FilterForm: () => JSX.Element;
}

const FilterForm = ({
  filterOptions,
  filterKey,
  setFilterKey,
  filterValueOptions,
  setFilterValue,
}) => {
  const [_filterValue, _setFilterValue] = useState<string>('');

  return (
    <Form layout="vertical">
      <Form.Item label="Filter" name="filter">
        <Input.Group compact style={{ width: '100%' }}>
          <Select
            style={{ width: '40%' }}
            placeholder="By"
            options={filterOptions}
            onChange={(value: string) => setFilterKey(value)}
            value={filterKey}
          />
          <AutoComplete
            style={{ width: '60%' }}
            placeholder="Value"
            allowClear
            options={filterValueOptions}
            filterOption={(inputValue, option) => {
              if (option) {
                const { value = '' } = option;
                return String(value).includes(inputValue);
              }
              return false;
            }}
            onChange={(value: string) => _setFilterValue(value)}
            onSelect={(value) => setFilterValue(value)}
            value={_filterValue}
          />
        </Input.Group>
      </Form.Item>
    </Form>
  );
};

export default <T,>(all: Array<T>): Response<T> => {
  /**
   *
   * Filter
   */

  const [filterOptions, setFilterOptions] = useState<Array<SelectOption>>([]);

  const [filterKey, setFilterKey] = useState<string>('');

  const [filterValue, setFilterValue] = useState<string>('');

  const [filterValueOptions, setFilterValueOptions] = useState<
    Array<AutocompleteOption>
  >([]);

  const [filterResults, setFilterResults] = useState<Array<T>>([]);

  useEffect(() => {
    if (all.length > 0) {
      const keys = flattenObject(all);

      const uniqKeySet = Array.from(
        new Set(keys.map((product) => Object.keys(product)).flat())
      ).filter((key) => !key.includes('items') && !key.includes('__typename'));

      const _filterOptions = uniqKeySet.map((key) => {
        return { label: key.replace('.', ' '), value: key };
      });

      setFilterOptions(_filterOptions);
    }
  }, [all]);

  useEffect(() => {
    setFilterValue('');

    if (filterKey) {
      const findAllOptions = all.map((item) => {
        return _get(item, filterKey, null);
      });

      const _filterValueOptions = Array.from(new Set(findAllOptions)).map(
        (o) => {
          return { value: o };
        }
      );

      setFilterValueOptions(_filterValueOptions);
    } else {
      setFilterValueOptions([]);
    }
  }, [filterKey]);

  useEffect(() => {
    if (filterKey) {
      const _filterResults = all.filter((item) => {
        const el = _get(item, filterKey, null);
        return el.includes(filterValue);
      });
      setFilterResults(_filterResults);
    } else {
      setFilterResults(all);
    }
  }, [filterKey, filterValue]);

  const _FilterForm = () => (
    <FilterForm
      filterOptions={filterOptions}
      filterKey={filterKey}
      setFilterKey={setFilterKey}
      filterValueOptions={filterValueOptions}
      setFilterValue={setFilterValue}
    />
  );

  return {
    filterOptions,
    setFilterOptions,
    filterKey,
    setFilterKey,
    filterValue,
    setFilterValue,
    filterValueOptions,
    setFilterValueOptions,
    filterResults,
    setFilterResults,
    FilterForm: _FilterForm,
  };
};
