import Type, {
  StandardProjectListItem_DTO,
} from 'Controller/StandardProject/StandardProject.type';
import { ReduxMiddlewareFunction } from 'Controller/middleware';
import { standardProjectActions } from 'Controller/actions';

import apiCallWrapper from 'Api/apiCallWrapper';
import { calculateUrl, restApi } from 'ApiRest/client';

type MemberMiddleware = Record<Type, ReduxMiddlewareFunction>;

const middleware: MemberMiddleware = {
  [Type.LIST_ALL]: async (store, next, action, apiClient) => {
    const { dispatch } = store;
    console.log('listAll middleware in standard project');

    await apiCallWrapper({
      dispatch,
      uiMessage: 'Standard Project ...',
      callback: async () => {
        const listStandardProjectsUrl = calculateUrl({
          subdomain: 'dashboard',
          path: 'api/admin/standard-projects',
        });

        const response = await restApi<StandardProjectListItem_DTO[]>(
          listStandardProjectsUrl
        );

        dispatch(
          standardProjectActions.setAll({
            projects: response,
          })
        );
      },
    });

    next(action);
  },

  [Type.SET_ALL]: async (store, next, action) => {
    next(action);
  },
  [Type.SET_FILTER]: async (store, next, action) => {
    const { dispatch } = store;

    await apiCallWrapper({
      dispatch,
      uiMessage: 'Standard Project ...',
      callback: async () => {
        const response = await restApi<StandardProjectListItem_DTO[]>(
          calculateUrl({
            subdomain: 'api',
            path: 'api/standard-projects',
            query: {
              status: action.payload.status as any as string,
            },
          })
        );

        dispatch(
          standardProjectActions.setAll({
            projects: response,
          })
        );
      },
    });

    next(action);
  },

  [Type.SELECT]: async (store, next, action) => {
    try {
      const {
        standardProject: { all: allStandardProjects },
      } = store.getState();

      const selectedProject = allStandardProjects.find(
        (t) => t.id === action.payload.id
      );

      if (!selectedProject) return;

      action.payload = {
        ...action.payload,
        selectedProject,
      };
    } catch (e) {
      console.error(e);
    } finally {
      next(action);
    }
  },
};

export default middleware;
