import React, { useState } from 'react';
import { Space, Button, Card, Spin, Typography, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import CsvToJson from 'csvtojson';
import { iconStyleOffset } from 'Components/common';
import { addActivity, removeActivity } from 'Controller/Network/Network.action';
import { useDispatch } from 'react-redux';
import { useRedux } from 'Hooks';

const ProductPricingUpload = () => {
  const [uploading, setUploading] = useState(false);

  const { dispatch, actions, store } = useRedux();

  const {
    productActions: { update },
  } = actions;

  const handleCsvUpload = (file) => {
    const reader = new FileReader();

    reader.onload = async (e) => {
      setUploading(true);
      const csvText = e.target?.result?.toString() || '';
      const csvArray = await CsvToJson().fromString(csvText);

      console.log(csvArray);

      try {
        const updatedProducts = csvArray
          .filter((csvProduct) => csvProduct.duration === '160 hours')
          .map((csvProduct) => {
            const existingProduct = store.product.all.find(
              (p) => p.id == csvProduct.productId
            );
            if (
              existingProduct &&
              csvProduct.showPrice &&
              existingProduct.showPrice !== csvProduct.showPrice
            ) {
              return {
                ...existingProduct,
                serviceId: existingProduct.service.id,
                vendorId: existingProduct.vendor.id,
                showPrice: csvProduct.showPrice,
              };
            }
            return null;
          })
          .filter(Boolean); // Remove null values

        console.log('updatedProducts', updatedProducts);
        // Dispatch update action for all changed products
        if (updatedProducts.length > 0) {
          updatedProducts.forEach((product) => {
            dispatch(update({ product }));
          });
        }

        const id = Math.random().toString(36).substring(7);
        setUploading(false);
        dispatch(
          addActivity({
            id,
            message: `Upload complete -- ${updatedProducts.length} show prices changed`,
            type: 'info',
          })
        );
        dispatch(removeActivity({ id }));
      } catch (error) {
        console.log(error);
      }
    };

    reader.readAsText(file);
    return false;
  };

  return (
    <Space
      style={{
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
      }}
      size="large"
    >
      <Typography.Title level={2} style={{ width: 762, textAlign: 'left' }}>
        Product Pricing Upload
      </Typography.Title>
      <Card
        title="CSV Upload"
        style={{ width: 762 }}
        bodyStyle={{ display: 'block' }}
      >
        <div>
          <Typography.Text>
            Please download the csv from COGs Bill of Materials page. Then set
            the markup/margin for each product and calculate the showPrice. We
            will only use the showPrice for 160 hours.
          </Typography.Text>
          <Typography.Text>
            This data will be used to show the average hourly rate for each
            product.
          </Typography.Text>
          <Typography.Text>
            Ensure the showPrice is the <strong>average hourly rate</strong> for
            each product.
          </Typography.Text>
        </div>
        {uploading ? (
          <Spin />
        ) : (
          <Upload accept=".csv" beforeUpload={handleCsvUpload} maxCount={1}>
            <Button
              type="link"
              icon={<UploadOutlined style={iconStyleOffset} />}
            >
              Upload CSV
            </Button>
          </Upload>
        )}
      </Card>
    </Space>
  );
};

export default ProductPricingUpload;
