enum Types {
  SET_ALL = '[PRODUCT] SET_ALL',
  LIST_ALL = '[PRODUCT] LIST_ALL',
  SELECT = '[PRODUCT] SELECT',
  UPDATE = '[PRODUCT] UPDATE',
  CREATE = '[PRODUCT] CREATE',
  REMOVE = '[PRODUCT] REMOVE',
  REMOVE_ITEM = '[PRODUCT] REMOVE_ITEM',
  UPSERT_ITEM = '[PRODUCT] UPSERT_ITEM',
}

export default Types;
