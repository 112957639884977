enum ProductItemTypes {
  LIST_ALL = '[PRODUCT_ITEM] LIST_ALL',
  SET_ALL = '[PRODUCT_ITEM] SET_ALL',
  SELECT = '[PRODUCT_ITEM] SELECT',
  UPDATE = '[PRODUCT_ITEM] UPDATE',
  UPDATE_CONFIGURATION = '[PRODUCT_ITEM] UPDATE_CONFIGURATION',
  CREATE = '[PRODUCT_ITEM] CREATE',
  REMOVE = '[PRODUCT_ITEM] REMOVE',
}

export default ProductItemTypes;
