import { ApolloClient } from 'apollo-client';
import fetch from 'node-fetch';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';

import { checkCookie } from 'Utils';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_INTERNALAPIURL, // @ts-ignore
  fetch,
});

const authLink = setContext(async () => {
  const cookieToken = checkCookie();

  return {
    headers: {
      Authorization: cookieToken,
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
    },
  },
});

export default client;
