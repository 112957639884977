import { Tenant, BillingAwsCosts } from 'Api/types.generated';
import Type from 'Controller/Tenant/Tenant.type';
import { StackSummary } from '@aws-sdk/client-cloudformation';
import { StackDeployment } from './Tenant.type';

export interface TenantState {
  all: Array<Tenant>;
  selected: string | null;
  awsCosts: BillingAwsCosts | null;
  selectedTenantStacks: StackSummary[];
  workspaceDeploymentStacks: Record<
    StackDeployment,
    { description: string; displayName: string }
  >;
}

const initalState: TenantState = {
  all: [],
  selected: null,
  awsCosts: null,
  selectedTenantStacks: [],
  workspaceDeploymentStacks: {
    [StackDeployment.ACM_CERTIFICATE]: {
      description:
        'AWS Certificate stack for the creation of the {tenant}.tyrellcct.cloud domain',
      displayName: 'ACM domain certificate service',
    },
    [StackDeployment.AD_CERTIFICATE_AUTHORITY]: {
      description:
        'Create the management and certification servers which connect to a directory',
      displayName: 'Active Directory certificate authority',
    },
    [StackDeployment.AD_FSX]: {
      description:
        'The managed Active Directory service and a shared file system',
      displayName: 'Active Directory and FSX',
    },
    [StackDeployment.DIRECTORY_INFRASTRUCTURE]: {
      description: 'Admin server, buckets and secrets for the directory',
      displayName: 'Active Directory infrastructure',
    },
    [StackDeployment.AD_ADMINS]: {
      description: 'Create the admin accounts in active directory',
      displayName: 'Active Directory admins',
    },
  },
};

type Action = {
  type: Type;
  payload: {
    tenants: Array<Tenant>;
    id: string | null;
    awsCosts: BillingAwsCosts;
    stacks: StackSummary[];
  };
};

export default function TenantReducer(
  state: TenantState = initalState,
  action: Action
): TenantState {
  const { type = '', payload } = action;

  switch (type) {
    case Type.SET_ALL: {
      const { tenants } = payload;
      return { ...state, all: tenants };
    }

    case Type.SELECT: {
      const { id } = payload;
      return { ...state, selected: id };
    }

    case Type.SET_AWS_COSTS: {
      const { awsCosts } = payload;
      return { ...state, awsCosts };
    }

    case Type.LIST_STACKS: {
      const { stacks } = payload;
      return { ...state, selectedTenantStacks: stacks };
    }

    default:
      return state;
  }
}
