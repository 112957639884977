import Types from './Member.type';

export const listAll = () => {
  return {
    type: Types.LIST_ALL,
    payload: null,
  };
};

export const setAll = ({ allMembers }: { allMembers: Array<any> }) => {
  return {
    type: Types.SET_ALL,
    payload: { allMembers },
  };
};

export const select = ({ id }: { id: string | null }) => {
  return {
    type: Types.SELECT,
    payload: { id },
  };
};

export const update = ({ member }: { member: any }) => {
  return {
    type: Types.UPDATE,
    payload: { member },
  };
};

export const create = ({ member }: { member: any }) => {
  return {
    type: Types.CREATE,
    payload: { member },
  };
};

export const remove = ({ id }: { id: string }) => {
  return {
    type: Types.REMOVE,
    payload: { id },
  };
};

export const getMe = () => {
  return {
    type: Types.GET_ME,
    payload: null,
  };
};

export const setThisSessionMember = ({ member }: { member: any }) => {
  return {
    type: Types.SET_THIS_SESSION_MEMBER,
    payload: { member },
  };
};
