import { useState, useEffect } from 'react';
import { useRedux } from 'Hooks';
import { Service } from 'Api/types.generated';
import { ButtonWithConfirmation } from 'Components/common/Button';

interface Values {
  id: string;
  name: string;
  description: string;
}

const remapToValues = (service: Service | null): Values => {
  return {
    id: service?.id || '',
    name: service?.name || '',
    description: service?.description || '',
  };
};

const InputForm = ({
  service,
  handleSubmit,
  handleDelete,
}: {
  service: Service | null;
  handleSubmit: (values: any) => void;
  handleDelete?: (id: string) => void;
}) => {
  const [values, setValues] = useState<Values>(remapToValues(service));

  useEffect(() => {
    setValues(remapToValues(service));
  }, [service]);

  return (
    <form>
      {service && (
        <div className="form-group">
          <label htmlFor="id">Id</label>
          <input
            disabled={true}
            type="text"
            className="form-control"
            id="id"
            value={values.id}
          />
          <small id="rdsId" className="form-text text-muted">
            Domain DB (AWS RDS)
          </small>
        </div>
      )}
      <div className="form-group">
        <label htmlFor="name">Name</label>
        <input
          type="text"
          className="form-control"
          id="name"
          value={values.name}
          onChange={(e) =>
            setValues({
              ...values,
              name: e.target.value,
            })
          }
        />
      </div>

      <div className="form-group">
        <label htmlFor="description">Description</label>
        <input
          type="text"
          className="form-control"
          id="description"
          value={values.description}
          onChange={(e) =>
            setValues({
              ...values,
              description: e.target.value,
            })
          }
        />
      </div>

      <div className="custom-form-button-group">
        <ButtonWithConfirmation
          buttonText={service ? 'Update' : 'Create'}
          handleConfirmation={() => handleSubmit(values)}
        />

        {service && handleDelete && (
          <ButtonWithConfirmation
            buttonText="Delete"
            style="danger"
            handleConfirmation={() => handleDelete(values.id)}
          />
        )}
      </div>
    </form>
  );
};

export const New = () => {
  const { dispatch, actions } = useRedux();

  const {
    serviceActions: { create },
  } = actions;

  const handleSubmit = (values: any) => {
    dispatch(create({ service: values }));
  };

  return <InputForm service={null} handleSubmit={handleSubmit} />;
};

export const Detail = () => {
  const { dispatch, actions, store } = useRedux();

  const {
    serviceActions: { update, remove },
  } = actions;

  const {
    service: { all, selected },
  } = store;

  const service = all.find((s) => s.id === selected);

  const handleSubmit = (values: any) => {
    dispatch(update({ service: values }));
  };

  const handleDelete = (id: string) => {
    dispatch(remove({ id }));
  };

  return (
    <InputForm
      service={service || null}
      handleSubmit={handleSubmit}
      handleDelete={handleDelete}
    />
  );
};
