import { useRedux } from 'Hooks';
import Form from 'Components/Product/Forms/Attributes';

const Detail = () => {
  const { dispatch, actions, store } = useRedux();

  const {
    productActions: { update, remove, create },
  } = actions;

  const {
    product: { all, selected },
  } = store;

  const product = all.find((p) => p.id === selected);

  const handleSubmit = (values: any) => {
    const { id } = values;

    if (!!id) {
      dispatch(update({ product: values }));
    } else {
      dispatch(create({ product: values }));
    }
  };

  const handleDelete = (id: string) => {
    dispatch(remove({ id }));
  };

  return (
    <Form
      product={product || null}
      handleSubmit={handleSubmit}
      handleDelete={handleDelete}
    />
  );
};

export default Detail;
