import { useRedux } from 'Hooks';
import RelationForm from 'Components/ProductItem/Forms/Relational';

const Configuration = () => {
  const { dispatch, actions, store } = useRedux();

  const {
    productItemActions: { updateConfiguration },
  } = actions;

  const {
    productItem: { all, selected },
  } = store;

  const productItem = all.find((p) => p.id === selected);

  const handleSubmit = (values: any) => {
    dispatch(updateConfiguration({ configuration: values }));
  };

  return (
    <RelationForm
      productItem={productItem || null}
      handleSubmit={handleSubmit}
    />
  );
};

export default Configuration;
