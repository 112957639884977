import { Space, Card, Typography } from 'antd';
import React, { useState } from 'react';
import _get from 'lodash.get';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

export const PageContainer = ({
  children,
  title,
}: {
  title: string;
  children: JSX.Element;
}) => {
  return (
    <Space
      style={{
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
      }}
      size="large"
    >
      <Typography.Title level={2} style={{ width: 762, textAlign: 'left' }}>
        {title}
      </Typography.Title>
      {children}
    </Space>
  );
};

export const CollapsableCard = ({
  children,
  title,
  id,
  actions,
}: {
  title: string;
  children: JSX.Element;
  id?: string;
  actions?: React.ReactNode[];
}) => {
  const [show, setShow] = useState(true);

  return (
    <Card
      title={title}
      style={{
        width: 762,
        boxShadow: 'rgba(100, 100, 111, 0.1) 0px 7px 29px 0px',
      }}
      id={id}
      actions={actions}
      extra={
        show ? (
          <DownOutlined onClick={() => setShow(false)} />
        ) : (
          <UpOutlined onClick={() => setShow(true)} />
        )
      }
      bodyStyle={{ display: show ? 'block' : 'none' }}
    >
      {children}
    </Card>
  );
};

export const iconStyleOffset = { transform: 'translateY(-2px)' };
