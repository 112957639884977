import Type from 'Controller/Vendor/Vendor.type';
import { ReduxMiddlewareFunction } from 'Controller/middleware';
import { vendorActions } from 'Controller/actions';
import { listVendors, updateVendor, createVendor, deleteVendor } from 'Api';
import apiCallWrapper from 'Api/apiCallWrapper';

type MemberMiddleware = Record<Type, ReduxMiddlewareFunction>;

const middleware: MemberMiddleware = {
  [Type.LIST_ALL]: async (store, next, action, apiClient) => {
    const { dispatch } = store;

    await apiCallWrapper({
      dispatch,
      uiMessage: 'Vendors ...',
      callback: async () => {
        const response = await apiClient.query({ query: listVendors });
        dispatch(vendorActions.setAll({ vendors: response.data.listVendors }));
      },
    });

    next(action);
  },

  [Type.UPDATE]: async (store, next, action, apiClient) => {
    const { dispatch } = store;

    const {
      vendor: { id, name },
    } = action.payload;

    await apiCallWrapper({
      dispatch,
      uiMessage: `Updating vendor ${name} ...`,
      callback: async () => {
        const response = await apiClient.mutate({
          mutation: updateVendor,
          variables: {
            id,
            name,
          },
        });
        dispatch(vendorActions.listAll());
        dispatch(vendorActions.select({ id: response.data.updateVendor.id }));
      },
    });

    next(action);
  },

  [Type.CREATE]: async (store, next, action, apiClient) => {
    const { dispatch } = store;

    const {
      vendor: { name },
    } = action.payload;

    await apiCallWrapper({
      dispatch,
      uiMessage: `Creating vendor ${name} ...`,
      callback: async () => {
        const response = await apiClient.mutate({
          mutation: createVendor,
          variables: {
            name,
          },
        });
        dispatch(vendorActions.listAll());
        dispatch(vendorActions.select({ id: response.data.createVendor.id }));
      },
    });

    next(action);
  },

  [Type.REMOVE]: async (store, next, action, apiClient) => {
    const { dispatch } = store;

    const { id } = action.payload;

    await apiCallWrapper({
      dispatch,
      uiMessage: `Deleting vendor ...`,
      callback: async () => {
        await apiClient.mutate({
          mutation: deleteVendor,
          variables: {
            vendorId: id,
          },
        });
        dispatch(vendorActions.listAll());
        dispatch(vendorActions.select({ id: null }));
      },
    });

    next(action);
  },

  [Type.SET_ALL]: async (store, next, action, apiClient) => {
    next(action);
  },

  [Type.SELECT]: async (store, next, action, apiClient) => {
    next(action);
  },
};

export default middleware;
