import { checkCookie } from 'Utils';

export const calculateUrl = ({
  subdomain,
  path,
  query,
}: {
  subdomain: string;
  path: string;
  query?: Record<string, string>;
}) => {
  const envMapper = {
    dev: 'dev-tyrellcct.cloud',
    qa: 'qa-tyrellcct.cloud',
    prod: 'tyrellcct.cloud',
  };

  const queryString =
    query && Object.keys(query).length
      ? `?${new URLSearchParams(query).toString()}`
      : '';

  return `https://${subdomain}.${
    envMapper[process.env.REACT_APP_TYRELL_ENV!]
  }/${path}${queryString}`;
};

export const restApi = async <T>(url: string, options?: RequestInit) => {
  const cookieToken = checkCookie();

  return fetch(url, {
    ...options,
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      Cookie: cookieToken,
      cookie: cookieToken,
      ...options?.headers,
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }

      return response.json() as Promise<T>;
    })
    .then((data) => {
      console.log('data from url', data);
      return data;
    });
};
