import Type, { InvitationRecord } from 'Controller/Onboarding/Onboarding.type';

export interface OnboardingState {
  invitations: Array<InvitationRecord>;
  sendStatus: boolean | null;
}

const initalState: OnboardingState = {
  invitations: [],
  sendStatus: null,
};

type Action = {
  type: Type;
  payload: {
    invitations: Array<InvitationRecord>;
    sendStatus: boolean | null;
  };
};

export default function MemberReducer(
  state: OnboardingState = initalState,
  action: Action
): OnboardingState {
  const { type = '', payload } = action;

  switch (type) {
    case Type.LIST: {
      const { invitations } = action.payload;
      return { ...state, invitations };
    }

    case Type.SET_SEND_STATUS: {
      const { sendStatus } = action.payload;
      return { ...state, sendStatus };
    }

    default:
      return state;
  }
}
