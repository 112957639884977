enum Types {
  INVITE = '[ONBOARDING] INVITE',
  LIST = '[ONBOARDING] LIST',
  RESEND = '[ONBOARDING] RESEND',
  REVOKE = '[ONBOARDING] REVOKE',
  SET_SEND_STATUS = '[ONBOARDING] SET_SEND_STATUS',
}

export enum InvitationStatus {
  SENT = 'SENT',
  ATTEMPTED = 'ATTEMPTED',
  ACCEPTED = 'ACCEPTED',
  REVOKED = 'REVOKED',
  RESENT = 'RESENT',
  FAILED = 'FAILED',
}

export type InvitedBy = {
  name: string;
  email: string;
};

export type InvitationRecord = {
  id: string;
  invitedBy: InvitedBy;
  email: string;
  status: InvitationStatus;
  expires: string;
  roleId: number;
  sent: string;
  edited: string;
  errorReason?: string;
};

export type InvitationRequest = {
  email: string;
  roleId: number;
};

export default Types;
