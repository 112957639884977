import { iStore } from 'Model';
import { useStore, useSelector } from 'react-redux';
import * as actions from 'Controller/actions';

const useRedux = () => {
  const { dispatch } = useStore();

  const store = useSelector((store: iStore) => {
    return store;
  });

  return { dispatch, store, actions };
};

export default useRedux;
