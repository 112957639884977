// add a cache busting comment to force recompile
// 2754766756

import { loader } from 'graphql.macro';

/**
 *
 * Onboarding
 */
const listInvitations = loader('./Onboarding/List.gql');
const sendInvitations = loader('./Onboarding/Invite.gql');
const resendInvitation = loader('./Onboarding/Resend.gql');
const revokeInvitation = loader('./Onboarding/Revoke.gql');

/**
 *
 * Tenant
 */

const listTenants = loader('./Tenant/List.gql');
const listTenantStacks = loader('./Tenant/ListTenantStacks.gql');
const getAwsBillingCost = loader('./Tenant/AwsCosts.gql');
const createTenantWithExistingOwner = loader(
  './Tenant/CreateTenantWithExistingOwner.gql'
);
const createTenantWithNewMember = loader(
  './Tenant/CreateTenantWithNewMember.gql'
);
const deployTenantActiveDirectory = loader(
  './Tenant/DeployTenantActiveDirectory.gql'
);
const deployTenantCertificateAuthority = loader(
  './Tenant/DeployTenantCertificateAuthority.gql'
);
const deployTenantCertificateStack = loader(
  './Tenant/DeployTenantCertificateStack.gql'
);
const deployDirectoryInfrastructure = loader(
  './Tenant/DeployDirectoryInfrastructure.gql'
);
const createActiveDirectoryAdmins = loader(
  './Tenant/CreateActiveDirectoryAdmins.gql'
);

/**
 *
 * Product
 */

const listProducts = loader('./Product/List.gql');
const createProduct = loader('./Product/Create.gql');
const upsertProduct = loader('./Product/Upsert.gql');
const deleteProduct = loader('./Product/Delete.gql');
const removeItemInProduct = loader('./Product/RemoveItemInProduct.gql');
const upsertItemInProduct = loader('./Product/UpsertItemInProduct.gql');

/**
 *
 * Product item
 */

const listProductItems = loader('./ProductItem/List.gql');
const createProductItem = loader('./ProductItem/Create.gql');
const deleteProductItem = loader('./ProductItem/Delete.gql');
const upsertProductItem = loader('./ProductItem/Upsert.gql');
const updateComputeConfiguration = loader('./ProductItem/UpdateCompute.gql');
const updateBlockConfiguration = loader('./ProductItem/UpdateBlock.gql');
const updateLicenseConfiguration = loader('./ProductItem/UpdateLicense.gql');
const updateNetworkConfiguration = loader('./ProductItem/UpdateNetwork.gql');
const updateGeneralConfiguration = loader('./ProductItem/UpdateGeneral.gql');

/**
 *
 * Member
 */

const getMe = loader('./Member/Me.gql');
const listMembers = loader('./Member/List.gql');
const updateMember = loader('./Member/Update.gql');
const deleteMember = loader('./Member/Delete.gql');

/**
 *
 * Service
 */

const listServices = loader('./Service/List.gql');
const createService = loader('./Service/Create.gql');
const upsertService = loader('./Service/Upsert.gql');
const deleteService = loader('./Service/Delete.gql');

/**
 *
 * Vendor
 */

const listVendors = loader('./Vendor/List.gql');
const createVendor = loader('./Vendor/Create.gql');
const updateVendor = loader('./Vendor/Update.gql');
const deleteVendor = loader('./Vendor/Delete.gql');

export {
  // onboarding
  listInvitations,
  sendInvitations,
  resendInvitation,
  revokeInvitation,
  // tenants
  listTenants,
  listTenantStacks,
  getAwsBillingCost,
  createTenantWithExistingOwner,
  createTenantWithNewMember,
  deployTenantActiveDirectory,
  deployTenantCertificateAuthority,
  deployTenantCertificateStack,
  deployDirectoryInfrastructure,
  createActiveDirectoryAdmins,
  // member
  listMembers,
  getMe,
  updateMember,
  deleteMember,
  // product
  listProducts,
  createProduct,
  upsertProduct,
  deleteProduct,
  removeItemInProduct,
  upsertItemInProduct,
  // service
  listServices,
  createService,
  upsertService,
  deleteService,
  // vendor
  listVendors,
  createVendor,
  updateVendor,
  deleteVendor,
  // item
  listProductItems,
  createProductItem,
  upsertProductItem,
  deleteProductItem,
  updateComputeConfiguration,
  updateBlockConfiguration,
  updateLicenseConfiguration,
  updateNetworkConfiguration,
  updateGeneralConfiguration,
};
