import Type from 'Controller/Member/Member.type';
import { ReduxMiddlewareFunction } from 'Controller/middleware';
import { memberActions } from 'Controller/actions';
import { getMe, listMembers } from 'Api';
import apiCallWrapper from 'Api/apiCallWrapper';

type MemberMiddleware = Record<Type, ReduxMiddlewareFunction>;

const middleware: MemberMiddleware = {
  [Type.LIST_ALL]: async (store, next, action, apiClient) => {
    const { dispatch } = store;
    const { setAll } = memberActions;

    await apiCallWrapper({
      dispatch,
      uiMessage: 'Members ...',
      callback: async () => {
        const response = await apiClient.query({ query: listMembers });
        dispatch(setAll({ allMembers: response.data.listMembers }));
      },
    });

    next(action);
  },

  [Type.GET_ME]: async (store, next, action, apiClient) => {
    const { dispatch } = store;
    const { setThisSessionMember } = memberActions;

    await apiCallWrapper({
      dispatch,
      uiMessage: 'You ...',
      callback: async () => {
        const response = await apiClient.query({ query: getMe });
        dispatch(setThisSessionMember({ member: response.data.getMe }));
      },
    });

    next(action);
  },

  [Type.SET_THIS_SESSION_MEMBER]: async (
    store: any,
    next: any,
    action: any
  ) => {
    next(action);
  },

  [Type.SET_ALL]: async (store, next, action, apiClient) => {
    next(action);
  },

  [Type.SELECT]: async (store, next, action, apiClient) => {
    next(action);
  },

  [Type.UPDATE]: async (store, next, action, apiClient) => {
    next(action);
  },

  [Type.CREATE]: async (store, next, action, apiClient) => {
    next(action);
  },

  [Type.REMOVE]: async (store, next, action, apiClient) => {
    next(action);
  },
};

export default middleware;
