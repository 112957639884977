import Type from 'Controller/ProductItem/ProductItem.type';
import { ReduxMiddlewareFunction } from 'Controller/middleware';
import { productItemActions } from 'Controller/actions';
import {
  listProductItems,
  deleteProductItem,
  upsertProductItem,
  createProductItem,
  updateComputeConfiguration,
  updateBlockConfiguration,
  updateLicenseConfiguration,
  updateNetworkConfiguration,
  updateGeneralConfiguration,
} from 'Api';
import apiCallWrapper from 'Api/apiCallWrapper';
import { sleep } from 'Utils';

type Middleware = Record<Type, ReduxMiddlewareFunction>;

const { listAll, setAll, select } = productItemActions;

const middleware: Middleware = {
  [Type.UPDATE_CONFIGURATION]: async (store, next, action, apiClient) => {
    const { dispatch } = store;

    const {
      configuration: {
        amiId,
        amiName,
        category,
        configId,
        duration,
        instanceType,
        iops,
        size,
        throughPut,
        loadBalancerType,
        volumeType,
        deployable,
        operatingSystem,
      },
    } = action.payload;

    await apiCallWrapper({
      dispatch,
      uiMessage: `Updating ${category} configuration ...`,
      callback: async () => {
        let response;

        if (category === 'NETWORK') {
          response = await apiClient.mutate({
            mutation: updateNetworkConfiguration,
            variables: {
              id: configId,
              loadBalancerType,
            },
          });
        }

        if (category === 'COMPUTE') {
          response = await apiClient.mutate({
            mutation: updateComputeConfiguration,
            variables: {
              id: configId,
              amiId,
              amiName,
              instanceType,
              operatingSystem,
            },
          });
        }

        if (category === 'BLOCK') {
          response = await apiClient.mutate({
            mutation: updateBlockConfiguration,
            variables: {
              id: configId,
              availabilityZone: 'eu-west-1',
              encryption: false,
              iops,
              size,
              throughPut,
              volumeType,
            },
          });
        }

        if (category === 'LICENSE') {
          response = await apiClient.mutate({
            mutation: updateLicenseConfiguration,
            variables: {
              id: configId,
              duration,
            },
          });
        }

        if (category === 'GENERAL') {
          response = await apiClient.mutate({
            mutation: updateGeneralConfiguration,
            variables: {
              id: configId,
              deployable,
            },
          });
        }

        response && dispatch(listAll());
      },
    });

    next(action);
  },

  [Type.LIST_ALL]: async (store, next, action, apiClient) => {
    const { dispatch } = store;

    await apiCallWrapper({
      dispatch,
      uiMessage: 'Product items ...',
      callback: async () => {
        const response = await apiClient.query({ query: listProductItems });

        dispatch(
          setAll({
            productItems: response.data.listProductItems,
          })
        );
      },
    });

    next(action);
  },

  [Type.REMOVE]: async (store, next, action, apiClient) => {
    const { dispatch } = store;

    const { id: productItemId } = action.payload;

    await apiCallWrapper({
      dispatch,
      uiMessage: 'Deleting product item ...',
      callback: async () => {
        await apiClient.mutate({
          mutation: deleteProductItem,
          variables: {
            productItemId,
          },
        });
        dispatch(listAll());
        dispatch(select({ id: null }));
      },
    });

    next(action);
  },

  [Type.CREATE]: async (store, next, action, apiClient) => {
    const { dispatch } = store;

    const { productItem } = action.payload;

    const { category, description, meta, name } = productItem;

    await apiCallWrapper({
      dispatch,
      uiMessage: `Creating ${name} ...`,
      callback: async () => {
        const response = await apiClient.mutate({
          mutation: createProductItem,
          variables: {
            category,
            name,
            meta,
            description,
          },
        });
        await sleep(10);
        dispatch(listAll());
        dispatch(select({ id: response.data.createProductItem.id }));
      },
    });

    next(action);
  },

  [Type.UPDATE]: async (store, next, action, apiClient) => {
    const { dispatch } = store;

    const { productItem } = action.payload;

    const { category, description, id, meta, name } = productItem;

    await apiCallWrapper({
      dispatch,
      uiMessage: `Updating ${name} ...`,
      callback: async () => {
        const response = await apiClient.mutate({
          mutation: upsertProductItem,
          variables: {
            id,
            category,
            name,
            meta,
            description,
          },
        });

        dispatch(listAll());
        dispatch(select({ id: response.data.upsertProductItem.id }));
      },
    });

    next(action);
  },

  [Type.SET_ALL]: (store, next, action, apiClient) => {
    next(action);
  },

  [Type.SELECT]: (store, next, action, apiClient) => {
    next(action);
  },
};

export default middleware;
