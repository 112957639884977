import Types from 'Controller/Tenant/Tenant.type';

export const listAll = () => {
  return {
    type: Types.LIST_ALL,
    payload: null,
  };
};

export const setAll = ({ tenants }: { tenants: Array<any> }) => {
  return {
    type: Types.SET_ALL,
    payload: { tenants },
  };
};

export const select = ({ id }: { id: string | null }) => {
  return {
    type: Types.SELECT,
    payload: { id },
  };
};

export const getAwsCosts = ({
  tenantId,
  start,
  end,
}: {
  tenantId: number;
  start: string;
  end: string;
}) => {
  return {
    type: Types.GET_AWS_COSTS,
    payload: { tenantId, start, end },
  };
};

export const setAwsCosts = ({ awsCosts }: { awsCosts: any }) => {
  return {
    type: Types.SET_AWS_COSTS,
    payload: { awsCosts },
  };
};

export const createNew = ({
  tenantName,
  tenantShortName,
  phoneNumber,
  givenName,
  familyName,
  email,
  ownerId = null,
  addressLine1,
  addressLine2,
  city,
  country,
  postcode,
  isDryRun = false,
}: {
  tenantName: string;
  tenantShortName: string;
  phoneNumber: string;
  givenName: string;
  familyName: string;
  email: string;
  ownerId: string | null;
  addressLine1: string;
  addressLine2: string;
  city: string;
  country: string;
  postcode: string;
  isDryRun: boolean;
}) => {
  return {
    type: Types.CREATE_NEW,
    payload: {
      tenantName,
      tenantShortName,
      phoneNumber,
      givenName,
      familyName,
      email,
      ownerId,
      addressLine1,
      addressLine2,
      city,
      country,
      postcode,
      isDryRun,
    },
  };
};

export const listStacks = () => {
  return {
    type: Types.LIST_STACKS,
    payload: {},
  };
};

export const deployStack = ({ deployment }: { deployment: string }) => {
  return {
    type: Types.DEPLOY_STACK,
    payload: { deployment },
  };
};

export const pageRoute = ({ path }: { path: string }) => {
  return {
    type: Types.PAGE_ROUTE,
    payload: { path },
  };
};
