import Type from 'Controller/Network/Network.type';

export interface Activity {
  id: string;
  message: string;
  type: 'success' | 'info' | 'warning' | 'error';
}

export interface Error {
  id: string;
  message: string;
}

export interface NetworkState {
  activities: Array<Activity>;
  errors: Array<Error>;
}

const initalState: NetworkState = {
  activities: [],
  errors: [],
};

type Action = {
  type: Type;
  payload: {
    activities: Array<Activity>;
    errors: Array<Activity>;
  };
};

export default function MemberReducer(
  state: NetworkState = initalState,
  action: Action
): NetworkState {
  const { type = '', payload } = action;

  switch (type) {
    case Type.ADD_ACTIVITY: {
      const { activities } = payload;
      return { ...state, activities };
    }

    case Type.REMOVE_ACTIVITY: {
      const { activities } = payload;
      return { ...state, activities };
    }

    case Type.ADD_ERROR: {
      const { errors } = payload;
      return { ...state, errors };
    }

    case Type.REMOVE_ERROR: {
      const { errors } = payload;
      return { ...state, errors };
    }

    default:
      return state;
  }
}
