import { useRedux } from 'Hooks';
import AttributeForm from 'Components/ProductItem/Forms/Attributes';

const Detail = () => {
  const { dispatch, actions, store } = useRedux();

  const {
    productItemActions: { update, remove, create },
  } = actions;

  const {
    productItem: { all, selected },
  } = store;

  const productItem = all.find((p) => p.id === selected);

  const handleSubmit = (values: any) => {
    const { id } = values;

    if (!!id) {
      dispatch(update({ productItem: values }));
    } else {
      dispatch(create({ productItem: values }));
    }
  };

  const handleDelete = (id: string) => {
    dispatch(remove({ id }));
  };

  return (
    <AttributeForm
      productItem={productItem || null}
      handleSubmit={handleSubmit}
      handleDelete={handleDelete}
    />
  );
};

export default Detail;
