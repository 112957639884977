import Types from './Product.type';

export const listAll = () => {
  return {
    type: Types.LIST_ALL,
    payload: null,
  };
};

export const setAll = ({ products }: { products: Array<any> }) => {
  return {
    type: Types.SET_ALL,
    payload: { products },
  };
};

export const select = ({ id }: { id: string | null }) => {
  return {
    type: Types.SELECT,
    payload: { id },
  };
};

export const update = ({ product }: { product: any }) => {
  return {
    type: Types.UPDATE,
    payload: { product },
  };
};

export const create = ({ product }: { product: any }) => {
  return {
    type: Types.CREATE,
    payload: { product },
  };
};

export const remove = ({ id }: { id: string }) => {
  return {
    type: Types.REMOVE,
    payload: { id },
  };
};

export const removeItem = ({
  productId,
  productItemId,
}: {
  productId: string;
  productItemId: string;
}) => {
  return {
    type: Types.REMOVE_ITEM,
    payload: { productId, productItemId },
  };
};

export const upsertItem = ({
  productId,
  productItemId,
  quantity,
}: {
  productId: string;
  productItemId: string;
  quantity: number;
}) => {
  return {
    type: Types.UPSERT_ITEM,
    payload: { productId, productItemId, quantity },
  };
};
