enum Types {
  SET_ALL = '[VENDOR] SET_ALL',
  LIST_ALL = '[VENDOR] LIST_ALL',
  SELECT = '[VENDOR] SELECT',
  UPDATE = '[VENDOR] UPDATE',
  CREATE = '[VENDOR] CREATE',
  REMOVE = '[VENDOR] REMOVE',
}

export default Types;
