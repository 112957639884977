import { networkActions } from 'Controller/actions';

const { addActivity, removeActivity, addError } = networkActions;

const apiCallWrapper = async <T>({
  dispatch,
  uiMessage,
  callback,
}: {
  dispatch;
  uiMessage;
  callback: () => Promise<T>;
}): Promise<T | null> => {
  const id = String(Date.now());

  let callbackResponse: T | null = null;

  try {
    dispatch(addActivity({ id, message: uiMessage, type: 'info' }));
    callbackResponse = await callback();
    dispatch(addActivity({ id, message: uiMessage, type: 'success' }));
    dispatch(removeActivity({ id }));
    return callbackResponse as T;
  } catch (e) {
    const _e = e as Error;
    dispatch(addError({ id, message: String(`${_e.name} - ${_e.message}`) }));
    return null;
  }
};

export default apiCallWrapper;
