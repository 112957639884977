import { useState, useEffect } from 'react';
import { Product } from 'Api/types.generated';
import { useRedux } from 'Hooks';
import { useMetaFieldValidation } from 'Hooks';
import { Form, Input, Select, Popconfirm, Button, Modal } from 'antd';
import { ExportOutlined } from '@ant-design/icons';

interface Values {
  id: string;
  name: string;
  meta: string;
  showPrice: number;
  category: string;
  description: string;
  publishedAt: string;
  serviceId: string;
  vendorId: string;
}

const { confirm } = Modal;

const showNewVendorModal = () => {
  confirm({
    title: 'Create new vendor',
    content: 'Some descriptions',
    onOk() {
      console.log('OK');
    },
    onCancel() {
      console.log('Cancel');
    },
  });
};

const showNewServiceModal = () => {
  confirm({
    title: 'Create new service',
    content: 'Some descriptions',
    onOk() {
      console.log('OK');
    },
    onCancel() {
      console.log('Cancel');
    },
  });
};

const remapToValues = (product: Product | null): Values => {
  return {
    id: product?.id || '',
    name: product?.name || '',
    meta: product?.meta || '{}',
    showPrice: product?.showPrice || 0.0,
    category: product?.category || 'WORKSTATION',
    description: product?.description || '',
    publishedAt: product?.publishedAt || '',
    serviceId: product?.service.id || '',
    // @ts-ignore
    vendorId: product?.vendor.id || '',
  };
};

const Attributes = ({
  product,
  handleSubmit,
  handleDelete,
}: {
  product: Product | null;
  handleSubmit: (values: any) => void;
  handleDelete?: (id: string) => void;
}) => {
  const { store } = useRedux();

  const {
    service: { all: allServices },
    vendor: { all: allVendors },
  } = store;

  const [values, setValues] = useState<Values>(remapToValues(product));

  useEffect(() => {
    setValues(remapToValues(product));
  }, [product]);

  const [metaFieldError] = useMetaFieldValidation(values.meta);

  return (
    <Form layout="vertical">
      {product && (
        <>
          <Form.Item label="ID">
            <Input disabled={true} type="text" value={values.id} />
          </Form.Item>
          <Form.Item label="Published at">
            <Input
              type="text"
              value={new Date(values.publishedAt).toLocaleString()}
              onChange={(e) =>
                setValues({
                  ...values,
                  publishedAt: e.target.value,
                })
              }
            />
          </Form.Item>
        </>
      )}

      <Form.Item label="Name">
        <Input
          type="text"
          value={values.name}
          onChange={(e) =>
            setValues({
              ...values,
              name: e.target.value,
            })
          }
        />
      </Form.Item>

      <Form.Item label="Description">
        <Input
          type="text"
          value={values.description}
          onChange={(e) =>
            setValues({
              ...values,
              description: e.target.value,
            })
          }
        />
      </Form.Item>

      <Form.Item label="Service">
        <Select
          value={values.serviceId}
          onChange={(value: string) =>
            setValues({
              ...values,
              serviceId: value,
            })
          }
          options={allServices.map((s) => {
            return { value: s.id, label: s?.name };
          })}
        />
        <Button
          type="link"
          icon={<ExportOutlined style={{ bottom: 3, position: 'relative' }} />}
          onClick={showNewServiceModal}
        >
          Create new
        </Button>
      </Form.Item>

      <Form.Item label="Vendor">
        <Select
          value={values.vendorId}
          onChange={(value: string) =>
            setValues({
              ...values,
              vendorId: value,
            })
          }
          options={allVendors.map((v) => {
            return { value: v.id, label: v?.name };
          })}
        />
        <Button
          type="link"
          icon={<ExportOutlined style={{ bottom: 3, position: 'relative' }} />}
          onClick={showNewVendorModal}
        >
          Create new
        </Button>
      </Form.Item>

      <Form.Item
        label="Meta"
        help={metaFieldError}
        validateStatus={metaFieldError ? 'error' : 'success'}
      >
        <Input
          type="text"
          value={values.meta}
          onChange={(e) =>
            setValues({
              ...values,
              meta: e.target.value,
            })
          }
        />
      </Form.Item>

      <Form.Item
        label="Show Price ($ for 160 hours)"
        help={metaFieldError}
        validateStatus={metaFieldError ? 'error' : 'success'}
      >
        <Input
          type="number"
          value={values.showPrice}
          lang="en"
          onChange={(e) =>
            setValues({
              ...values,
              showPrice: Number(e.target.value),
            })
          }
        />
      </Form.Item>

      <div className="custom-form-button-group">
        <Popconfirm
          title={product ? 'Update' : 'Create'}
          onConfirm={() => handleSubmit(values)}
          okText="Yes"
          cancelText="No"
        >
          <Button type="primary">{product ? 'Update' : 'Create'}</Button>
        </Popconfirm>
        {product && handleDelete && (
          <Popconfirm
            title={'Delete'}
            onConfirm={() => handleDelete(values.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary" danger>
              Delete
            </Button>
          </Popconfirm>
        )}
      </div>
    </Form>
  );
};

export default Attributes;
