import { Table } from 'antd';
import { useRedux, useFilter } from 'Hooks';
import { Item } from 'Api/types.generated';
import React, { useEffect, useState } from 'react';
import type { ColumnsType } from 'antd/es/table';

interface DataType {
  key: React.Key;
  name: string;
  description: string;
  category: string;
}

export default ({ handleSelection }) => {
  const [columns, setColumns] = useState<ColumnsType<DataType>>([]);

  const { store } = useRedux();

  const {
    productItem: { all },
  } = store;

  const { filterResults, FilterForm } = useFilter<Item>(all);

  useEffect(() => {
    setColumns([
      { title: 'Name', dataIndex: 'name', key: 'name' },
      {
        title: 'Category',
        dataIndex: 'category',
        key: 'category',
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
      },
    ]);
  }, []);

  return (
    <>
      <FilterForm />
      <Table
        rowSelection={{
          type: 'radio',
          onChange: handleSelection,
        }}
        dataSource={filterResults.map((fr) => {
          const { id, name, category, description } = fr;

          return {
            key: id,
            name,
            category: String(category),
            description,
          };
        })}
        columns={columns}
      />
    </>
  );
};
