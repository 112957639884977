import { Member } from 'Api/types.generated';
import Type from 'Controller/Member/Member.type';

export interface MemberState {
  thisSessionMember: Member | null;
  all: Array<Member>;
  selected: string | null;
}

const initalState: MemberState = {
  thisSessionMember: null,
  all: [],
  selected: null,
};

type Action = {
  type: Type;
  payload: {
    member: Member;
    allMembers: Array<Member>;
    id: string | null;
  };
};

export default function MemberReducer(
  state: MemberState = initalState,
  action: Action
): MemberState {
  const { type = '', payload } = action;

  switch (type) {
    case Type.SET_THIS_SESSION_MEMBER: {
      const { member } = payload;
      return { ...state, thisSessionMember: member };
    }

    case Type.SET_ALL: {
      const { allMembers } = payload;
      return { ...state, all: allMembers };
    }

    case Type.SELECT: {
      const { id } = payload;
      return { ...state, selected: id };
    }

    default:
      return state;
  }
}
