import { useState, useEffect } from 'react';
import { Member } from 'Api/types.generated';
import { ButtonWithConfirmation } from 'Components/common/Button';

interface Values {
  id: string;
  givenName: string;
  familyName: string;
  email: string;
  phoneNumber: string;
}

const remapToValues = (member: Member | null): Values => {
  return {
    id: member?.id || '',
    givenName: member?.givenName || '',
    familyName: member?.familyName || '',
    email: member?.email || '',
    phoneNumber: member?.phoneNumber || '',
  };
};

const Form = ({
  member,
  handleSubmit,
  handleDelete,
}: {
  member: Member | null;
  handleSubmit: (values: any) => void;
  handleDelete?: (id: string) => void;
}) => {
  const [values, setValues] = useState<Values>(remapToValues(member));

  useEffect(() => {
    setValues(remapToValues(member));
  }, [member]);

  return (
    <form>
      {member && (
        <div className="form-group">
          <label htmlFor="id">Id</label>
          <input
            disabled={true}
            type="text"
            className="form-control"
            id="id"
            value={values.id}
          />
          <small id="rdsId" className="form-text text-muted">
            Set by AWS Cognito Idp
          </small>
        </div>
      )}

      <div className="form-group">
        <label htmlFor="name">Given name</label>
        <input
          type="text"
          className="form-control"
          id="givenName"
          value={values.givenName}
          onChange={(e) =>
            setValues({
              ...values,
              givenName: e.target.value,
            })
          }
        />
      </div>

      <div className="form-group">
        <label htmlFor="name">Family name</label>
        <input
          type="text"
          className="form-control"
          id="familyName"
          value={values.familyName}
          onChange={(e) =>
            setValues({
              ...values,
              familyName: e.target.value,
            })
          }
        />
      </div>

      <div className="form-group">
        <label htmlFor="name">Email</label>
        <input
          type="email"
          className="form-control"
          id="email"
          value={values.email}
          onChange={(e) =>
            setValues({
              ...values,
              email: e.target.value,
            })
          }
        />
      </div>

      <div className="form-group">
        <label htmlFor="name">Phone</label>
        <input
          type="text"
          className="form-control"
          id="phoneNumber"
          value={values.phoneNumber}
          onChange={(e) =>
            setValues({
              ...values,
              phoneNumber: e.target.value,
            })
          }
        />
      </div>

      <div className="custom-form-button-group">
        <ButtonWithConfirmation
          buttonText={member ? 'Update' : 'Create'}
          handleConfirmation={() => handleSubmit(values)}
        />

        {member && handleDelete && (
          <ButtonWithConfirmation
            buttonText="Delete"
            style="danger"
            handleConfirmation={() => handleDelete(values.id)}
          />
        )}
      </div>
    </form>
  );
};

export default Form;
