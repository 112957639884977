enum Types {
  SET_THIS_SESSION_MEMBER = '[MEMBER] SET_THIS_SESSION_MEMBER',
  GET_ME = '[MEMBER] GET_ME',
  SET_ALL = '[MEMBER] SET_ALL',
  LIST_ALL = '[MEMBER] LIST_ALL',
  SELECT = '[MEMBER] SELECT',
  UPDATE = '[MEMBER] UPDATE',
  CREATE = '[MEMBER] CREATE',
  REMOVE = '[MEMBER] REMOVE',
}

export default Types;
