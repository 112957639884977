import { Fragment, useEffect, useState } from 'react';
import { useRedux, useFilter } from 'Hooks';
import { Member } from 'Api/types.generated';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';

interface DataType {
  key: React.Key;
  first: string;
  last: string;
  email: string;
  phone: string;
}

const ListAllMembers = ({ handleSelection }) => {
  const [columns, setColumns] = useState<ColumnsType<DataType>>([]);

  const { store } = useRedux();

  const {
    member: { all },
  } = store;

  const { filterResults, FilterForm } = useFilter<Member>(all);

  useEffect(() => {
    setColumns([
      { title: 'First', dataIndex: 'first', key: 'first' },
      {
        title: 'Last',
        dataIndex: 'last',
        key: 'last',
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Phone',
        dataIndex: 'phone',
        key: 'phone',
      },
    ]);
  }, []);

  return (
    <Fragment>
      <FilterForm />
      <Table
        rowSelection={{
          type: 'radio',
          onChange: handleSelection,
        }}
        dataSource={filterResults.map((fr) => {
          const { id, givenName, familyName, email, phoneNumber } = fr;

          return {
            key: id,
            first: givenName,
            last: familyName,
            email,
            phone: phoneNumber,
          };
        })}
        columns={columns}
      />
    </Fragment>
    // <table className="table">
    //   <thead>
    //     <tr>
    //       <th scope="col">First</th>
    //       <th scope="col">Last</th>
    //       <th scope="col">Email</th>
    //     </tr>
    //   </thead>
    //   <tbody>
    //     {all.map((m, i) => {
    //       const isSelectClassMod = m.id === selected ? '--selected' : '';

    //       return (
    //         <tr
    //           key={m?.id}
    //           onClick={() => m?.id && dispatch(select({ id: m.id }))}
    //           className={`${isSelectClassMod}`}
    //         >
    //           <td>{m?.givenName}</td>
    //           <td>{m?.familyName}</td>
    //           <td>{m?.email}</td>
    //         </tr>
    //       );
    //     })}
    //   </tbody>
    // </table>
  );
};

export default ListAllMembers;
